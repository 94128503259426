import Sidebar from "../../components/Sidebar";
import AdminPageHeader from "../../components/Header/AdminPageHeader";
import React, {Fragment, useEffect, useState} from "react";
import {Dialog, Transition} from "@headlessui/react";
import {RiCloseLine} from "react-icons/ri";
import {getFetcher} from "../../utils/fetcher";
import {ALL_STORE_LIST_SIMPLE_API} from "../../constants/api_constants";
import {toast} from "react-toastify";
import DatePicker from "react-datepicker";
import {ko} from "date-fns/locale";
import DatePickerInput from "../../components/DatePickerInput";
import {roundDefaultList, roundInfo} from "../ticketListPage/base/BaseTicketListPage";
import ListSelectBoxWithDefaultData from "../../components/ListSelectBoxWithDefaultData";
import {round_management_table_body} from "../../utils/_dummyData";
import {accountInfo} from "../../constants/constants";

const MonitoringStorePage = () => {
  const [searchStoreText, setSearchStoreText] = useState('');
  const [searchLogText, setSearchLogText] = useState('');
  const [allStoreList, setAllStoreList] = useState([]); // 모든 지점 리스트
  const [filteredStoreList, setFilteredStoreList] = useState(allStoreList);
  const [selectedStoreList, setSelectedStoreList] = useState([]); // 선택한 지점 리스트
  const [startDate, setStartDate] = useState(new Date()); // DatePicker
  const [endDate, setEndDate] = useState(new Date());
  const [selectedRound, setSelectedRound] = useState(roundDefaultList[0]); // 회차
  const [logData, setLogData] = useState(round_management_table_body);

  useEffect(() => {
    getStoreList();
  }, []);

  useEffect(() => {
    let _searchText = searchStoreText.toLowerCase().trim().replace(" ", "");
    // 검색어와 일치하는 지점 리스트 반환
    if (_searchText === '') {
      setFilteredStoreList(allStoreList);
    } else {
      setFilteredStoreList(
          allStoreList.filter((store) =>
              store.storeName.toLowerCase().replace(" ", "").trim().includes(_searchText))
      );
    }
  }, [searchStoreText, allStoreList]);

  const getStoreList = () => {
    let date_ = new Date().getTime();
    // <-- 지점 리스트 정보 가져오기 -->
    getFetcher().get(ALL_STORE_LIST_SIMPLE_API+"?userId="+accountInfo.accountId())
        .then(res => {
          const {header, result} = res.data;
          if (header.type === 'success') {
            console.log('time-delay:getStoreList:' + (new Date().getTime() - date_));
            setAllStoreList(result.storeList.sort((d1, d2) => {
              // 이름 정렬
              return d1.storeName.localeCompare(d2.storeName);
            }))
          }
        }).catch(err => toast.error(err.message));
  }

  const handleSelectStore = (storeCode) => {
    // <-- 지점 체크박스 핸들링 -->
    setSelectedStoreList((prevList) => {
      let _findItem = prevList.find((item) => item.storeCode === storeCode);
      if (_findItem !== undefined) {
        // 이미 선택되어있으면 빼기
        return prevList.filter(item => item !== _findItem);
      } else {
        let _findItem = allStoreList.find((item) => item.storeCode === storeCode);

        if (_findItem !== undefined) {
          // 선택 안된 지점이면 추가
          return [...prevList, _findItem].sort((d1, d2) => {
            // 이름 정렬
            return d1.storeName.localeCompare(d2.storeName);
          });
        }
        return prevList;
      }
    });
  };

  const removeSelectStore = (store) => {
    // <-- 지점 선택해제 -->
    setSelectedStoreList((prevList) => prevList.filter(item => item.storeCode !== store.storeCode))
  }

  return (
      <div className="bg-gray-100 min-h-screen flex flex-col">
        <Sidebar/>
        <main className="lg:pl-64 h-full flex-1 flex flex-col">
          <AdminPageHeader/>

          <div className="w-full flex-grow sm:pt-16 flex flex-row">
            {/* <-- 지점 리스트 띄우기 영역 --> */}
            <div className="w-1/4 flex-grow px-10">
              <div className="mt-3 text-center sm:mt-5">
                <h3 className="text-xl font-semibold leading-6 text-gray-900"> 지점 선택 </h3>
                <p className="text-sm mt-2 text-blue-600">※ 하나라도 선택하지 않으면, 모든 지점으로 간주됩니다. </p>
              </div>

              <div className="flex flex-col justify-center mt-3">
                <div className="flex flex-row">
                  <input
                      onChange={(e) => setSearchStoreText(e.target.value)}
                      onFocus={(e) => e.target.select()}
                      placeholder={"지점 이름 검색"}
                      spellCheck="false"
                      value={searchStoreText}
                      className="block w-full pl-3 rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-gray-300 placeholder:text-gray-400 sm:text-sm sm:leading-6"
                  />
                </div>

                <div className="flex flex-row flex-wrap my-2">
                  {/*선택된 지점들*/}
                  {selectedStoreList.length === 0 ?
                      <span
                          className="my-1 mr-2 flex flex-row justify-center rounded-md bg-blue-300 font-semibold px-1.5 py-1 text-sm shadow-sm whitespace-nowrap items-center">
                            전체 지점
                          </span> :
                      selectedStoreList.map((store) => {
                        return (
                            <button
                                onClick={() => removeSelectStore(store)}
                                className="my-1 mr-2 flex flex-row justify-center rounded-md bg-yellow-300 font-semibold px-1.5 py-1 text-sm shadow-sm whitespace-nowrap items-center"
                            >
                              <p className="text-sm">{store.storeName}</p>
                              <RiCloseLine className="ml-1 h-4 w-4 text-black"/>
                            </button>
                        )
                      })}
                </div>

                <div className="flex flex-col justify-between mt-1 max-h-[30rem] overflow-y-scroll">
                  {/* 지점 리스트*/}
                  {filteredStoreList.map((store) => {
                    return (
                        <div className="flex flex-row items-center mb-2 cursor-pointer"
                             onClick={() => handleSelectStore(store.storeCode)}>
                          <input type="checkbox"
                                 checked={selectedStoreList.find((item) => item.storeCode === store.storeCode) !== undefined}
                                 className="h-4 w-4 rounded border-gray-300 text-indigo-600 focus:ring-indigo-600 mr-2"
                          />
                          <p className="text-sm"> {store.storeName} </p>&nbsp;
                          <p className="text-xs text-gray-600"> ({store.storeCode})</p>
                        </div>
                    )
                  })}
                </div>
              </div>
            </div>

            {/* <-- 로그 띄우기 영역 --> */}
            <div className="w-3/4 flex-grow px-5">
              <div className="flex flex-row h-[2.5rem] items-center pt-5">
                {/* 검색 라인 */}
                <div
                    className="whitespace-nowrap mr-3 flex flex-row text-sm justify-between px-5 py-2 ring-1 ring-gray-300 rounded-3xl text-center w-64 bg-white">
                  <DatePicker
                      locale={ko}
                      dateFormat='yyyy-MM-dd'
                      selected={startDate}
                      onChange={date => setStartDate(date)}
                      customInput={<DatePickerInput/>}
                  />
                  <span className="mx-2"> ~ </span>
                  <DatePicker
                      locale={ko}
                      dateFormat='yyyy-MM-dd'
                      selected={endDate}
                      onChange={date => setEndDate(date)}
                      customInput={<DatePickerInput/>}
                  />
                </div>
                <ListSelectBoxWithDefaultData defaultData={selectedRound} listData={roundInfo.list}
                                              onChange={setSelectedRound}/>
                <input
                    onChange={(e) => setSearchLogText(e.target.value)}
                    spellCheck="false"
                    placeholder={"데이터 검색"}
                    value={searchLogText}
                    className="block w-80 pl-3 rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-gray-300 placeholder:text-gray-400 sm:text-sm sm:leading-6"
                />
                <button
                    onClick={null}
                    className="whitespace-nowrap rounded-md bg-blue-900 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-blue-800 ml-3">
                  검색
                </button>
              </div>

              {/*로그 화면*/}
              <div className="w-full max-h-[calc(100%-2.5rem)] py-7">
                <div
                    className="w-full max-h-[46rem] rounded-lg border border-gray-300 bg-white shadow-md overflow-y-auto py-3">
                  {logData.map((line, index) => {
                    return (
                        <p key={index}
                           className={`h-8 text-sm flex items-center px-5 ${index % 2 === 0 ? 'bg-gray-100' : 'bg-white'} hover:bg-amber-100`}>
                          {line.code}
                        </p>
                    )
                  })}
                </div>
              </div>
            </div>
          </div>
        </main>
      </div>
  );
}
export default MonitoringStorePage;