import {getLogoImage} from "../../constants/constants";
import React from "react";
import neverLandGameData from "../../utils/NeverLandGameData";


const CopyByOriginTicketCard = ({ticketData, ticketOrderType}) => {
  const getGameRound = (roundData) => {
    // 회차정보
    let round = roundData.substring(7);
    if (round.startsWith('0')) {
      return round.substring(1);
    } else {
      return round;
    }
  }

  if (ticketData.copyNumber === undefined) {
    ticketData.copyNumber = 1;
  }

  return (
      <div className="flex flex-col">
        <p className="font-semibold text-xl text-center mt-5">원본티켓</p>
        <div key={ticketData.id}
             className="px-1 mt-4 bg-white shadow w-ticket flex flex-row ring-1 ring-gray-400"
        >
          <div className="py-7 px-2 w-full"
               style={{
                 backgroundImage: `url(${getLogoImage('ticketOrange')})`,
                 backgroundSize: "100% auto",
                 backgroundRepeat: "no-repeat",
                 backgroundPosition: "center"
               }}>
            <div className="flex flex-col">
              <div className="flex flex-row justify-between items-center">
                <img src={getLogoImage('textLogoBlack')} alt="MobydickLogo"
                     className="object-center object-contain w-32 mx-1"/>
              </div>
              <div className="flex flex-row mt-2 justify-between items-center">
                <p className="text-xl font-bold tracking-tight text-gray-900"> 프로토(승부식)</p>
                <p className="text-xl font-bold tracking-tight text-gray-900">{getGameRound(ticketData.gameRound)}회차 </p>
              </div>
            </div>
            {ticketOrderType.view.includes('charger') &&
                <div className="text-right text-sm mt-1">
                  <p>{ticketData.chargerName}</p>
                  <p>{ticketData.registerMemo.split(')')[0].trim()}</p>
                </div>
            }

            <p className="text-sm text-right">경기시작 : {ticketData.closingDate.substring(0, 10)}</p>

            <p className="font-semibold text-sm text-right mt-2"> {ticketData.selectGame.length}경기 선택 </p>
            <div>
              <div className="text-xs font-semibold w-full mt-3 text-center">
                <div className="border-t-2 border-dashed border-gray-500 mb-1"></div>
                <div className="flex">
                  <div className="w-2/12">경기</div>
                  <div className="w-4/12">홈팀</div>
                  <div>:</div>
                  <div className="w-3/12">원정팀</div>
                  <div className="w-1/12">예상</div>
                  <div className="w-2/12">배당률</div>
                </div>
                <div className="border-t-2 border-dashed border-gray-500 mt-1"></div>
              </div>
              {(
                  <div>
                    <div key={ticketData.id}>
                      {ticketData.selectGame?.map((game) => {
                        return (
                            <div key={game.allotId}
                                 className={game.allotResult === '-' ? 'text-xs w-full flex text-center mt-1' :
                                     game.allotResult === 'o' ? 'text-xs w-full flex text-center bg-green-100 mt-1' :
                                         'text-xs w-full flex text-center bg-red-200 mt-1 items-center'
                                 }
                            >
                              <div className="w-2/12">{game.gameNumber.replace("⒮", "S")}</div>
                              <div className="w-4/12">{game.homeTeam}</div>
                              <div>:</div>
                              <div className="w-3/12">{game.awayTeam}</div>
                              <div className="w-1/12">{neverLandGameData.getPrediction(game.gameNumber, game.prediction)}</div>
                              <div className="w-2/12">{game.rate}</div>
                            </div>
                        );
                      })}
                    </div>
                  </div>
              )}
              <div className="border-t-2 border-dashed border-gray-500 mt-1"></div>
            </div>

            <div className="flex flex-row justify-between items-center mt-3">
              <p className="text-base font-semibold col-span-6">예상 적중배당률 :</p>
              <div className="flex flex-row col-span-6">
                <p className="text-lg font-semibold text-black mr-3">{ticketData.totalRate}</p>
                <p className="text-lg font-semibold">배</p>
              </div>
            </div>

            <div className="flex flex-row justify-between items-center mt-3">
              <p className="text-base font-semibold col-span-6">개별투표금액 :</p>
              <div className="flex flex-row col-span-6">
                <p className="text-lg font-semibold">{ticketData.amount?.toLocaleString()}원</p>
              </div>
            </div>

            <div className="flex flex-row justify-between items-center mt-3">
              <p className="text-base font-semibold col-span-6">예상 적중금 :</p>
              <div className="flex flex-row col-span-6">
                <p className="text-lg font-semibold text-black mr-3">
                  {(Math.floor((ticketData.amount || 0) * (ticketData.totalRate || 0))).toLocaleString() || 0}
                </p>
                <p className="text-lg font-semibold">원</p>
              </div>
            </div>


            <div className="flex flex-row items-center mt-4">
              <p className="text-lg font-semibold mr-3">총 투표금액</p>
              <p className="text-lg font-semibold mr-1">{ticketData.amount?.toLocaleString() || 0}</p>
              <p className="text-lg font-semibold">원</p>
            </div>

            {ticketOrderType.view.includes('register') &&
                <>
                  <p className="text-xs font-semibold mt-4"> {ticketData.registerName}</p>
                  <p className="text-sm font-semibold mt-4"> {ticketOrderType.view.includes('charger') ? (ticketData.registerMemo.includes(')') ? ticketData.registerMemo.split(')')[1] : "") : ticketData.registerMemo}</p>
                </>
            }
            {ticketOrderType.view.includes('charger') || ticketOrderType.view.includes('charger-memo-only') &&
                <div>
                  {!ticketOrderType.view.includes('charger-memo-only') &&
                      <p className="text-xs font-semibold mt-4"> {ticketData.chargerName}</p>
                  }
                  <p className="text-sm font-semibold mt-4">{ticketData.chargerMemo}</p>
                </div>
            }
          </div>

          <div className="w-5 ml-3"
               style={{backgroundImage: `url(${getLogoImage('ticketSideOrange')})`}}></div>
        </div>

      </div>
  )
}
export default CopyByOriginTicketCard;
