import {_getResourcePath} from "../constants/constants";
import {getFetcher} from "../utils/fetcher";
import {CHECK_VERSION_API} from "../constants/api_constants";
import {useEffect} from "react";
import neverLandUtils from "../utils/NeverLandUtils";
import {useNavigate} from "react-router-dom";
import {MAIN_PAGE_TEMP} from "../constants/page_constants";

const ForbidPage = () => {
  const img_src = _getResourcePath("http://teamneverland.com/resources/img/etc/modify.png");
  const navigate = useNavigate();

  useEffect(() => {
    // 60초마다 버전체크 api 실행
    const interval = setInterval(() => {
      checkVersion();
    }, 60000);

    // 컴포넌트 언마운트 시 인터벌 정리
    return () => clearInterval(interval);
  }, []);

  const checkVersion = () => {
    getFetcher().get(CHECK_VERSION_API)
        .then(res => {
          const {type} = res.data.header;
          // 버전이 정상적으로 나오면 마지막 경로 지우고 메인으로 이동
          if (type === 'success') {
            neverLandUtils.commons.remove('last_path');
            navigate(MAIN_PAGE_TEMP);
            setTimeout(() => {
              window.location.reload();
            }, 1000)
          }
        }).catch(err => {
      console.log(err);
    })
  }

  return (
      <div className="flex items-center justify-center min-h-screen">
        <main className="text-center">
          <p className="text-4xl font-bold mt-4 mb-5"> 사이트 점검안내 </p>
          <img src={img_src} alt='forbidBackGroundImg' className="w-full sm:w-1/4 h-auto mx-auto"/>

          <div className="bg-red-500 inline-block px-5 py-1 rounded-2xl mt-5">
            <p className="lg:text-xl text-sm font-semibold text-white">
              점검 예정 시간 : 2024년 9월 2일 12:00 ~ 24:00
            </p>
          </div>

          <p className="lg:text-xl text-sm font-semibold mt-4">보다 나은 서비스 제공을 위한 점검 시간입니다.</p>
          <p className="lg:text-xl text-sm font-semibold mt-4 px-3">빠른 시간 내에 정상적인 서비스가 가능하도록 최선을 다하겠습니다.</p>
        </main>
      </div>
  )
}
export default ForbidPage;