import BaseTicketListPage from "./base/BaseTicketListPage";
import React, {useEffect, useState} from "react";
import Sidebar from "../../components/Sidebar";
import {accountInfo} from "../../constants/constants";
import {toast} from "react-toastify";
import {getFetcher} from "../../utils/fetcher";
import {
  CHANGE_GAME_STATE_TO_ABORT_API, CHANGE_GAME_STATE_TO_CANCEL_API,
  CHANGE_GAME_STATE_TO_PRINT_DONE_API, CHANGE_GAME_STATE_TO_REQUEST_API,
  CHANGE_GAME_STATE_TO_REQUEST_CANCEL_API
} from "../../constants/api_constants";
import ChangeGameStateCancelModal from "../../components/Modal/ChangeGameStateCancelModal";
import {customToast} from "../../utils/customToast";
import {setStorePrintDoneHandler} from "../WindowInterfaces";
import neverLandNotificationInterface from "../../utils/NeverLandNotificationInterface";
import CopyTicketRequestModal from "../../components/Modal/CopyTicketRequestModal";

function getMatchSeqFromAllotId(allotId) {
  return parseInt(allotId.substring(allotId.lastIndexOf('_') + 1), 10);
}

const StoreRequestListPage = () => {
  const [isRequesting, setIsRequesting] = useState(false);
  const [requestCancelModalOpen, setRequestCancelModalOpen] = useState([]);
  const [showCopyRequestModal, setShowCopyRequestModal] = useState({});
  const [refreshDate, setRefreshDate] = useState(new Date());
  // const OpenModal = OpenModalHandle.create();


  const _onNotificationChanged = (notificationList) => {
    setRefreshDate(new Date());
  }
  useEffect(() => {

    neverLandNotificationInterface.registMonitor(
        {programKey: 'mobydick', notifyKey: 'order_list_for_store', accountKey: accountInfo.accountId()},
        _onNotificationChanged);

    return () => {
      neverLandNotificationInterface.unRegistMonitor(_onNotificationChanged);
    }
  }, []);

  useEffect(() => {
    setStorePrintDoneHandler(() => {
      setRefreshDate(new Date());
    });
    return () => {
      setStorePrintDoneHandler(null);
    };
  }, []);

  const validateTicketAmount = (errorList, ticket) => {
    // <-- 요청시 규정 적용 -->
    if (ticket.selectGame.length === 1) {
      // 단폴더일때
      if (ticket.amount > 500000) {
        errorList.push("요청 " + ticket.id + '번 : 금액초과 - 1폴더는 50만원을 넘길 수 없습니다.');
        return false;
      }
      if (ticket.totalRate <= 1.3) {
        errorList.push("요청 " + ticket.id + '번 : 배당부족 - 1폴더는 1.31배 이상 요청 가능합니다.');
        return false;
      }
    } else {
      // 다폴더일때
      if (ticket.amount > 1000000) {
        errorList.push("요청 " + ticket.id + '번 : 금액초과 - 다폴더는 100만원을 넘길 수 없습니다.');
        return false;
      }
      if (ticket.totalRate <= 1.5) {
        errorList.push("요청 " + ticket.id + '번 : 배당부족 - 다폴더는 1.51배 이상 요청 가능합니다.');
        return false;
      }
      if (ticket.totalRate > 100) {
        errorList.push("요청 " + ticket.id + '번 : 배당초과 - 100배당 초과시 요청 불가합니다.');
        return false;
      }
    }
    // 공통 적용
    if (ticket.amount < 1000) {
      errorList.push("요청 " + ticket.id + '번 : 금액부족 - 1,000원부터 요청이 가능합니다.');
      return false;
    }
    if (ticket.totalRate * ticket.amount > 10000000) {
      errorList.push("요청 " + ticket.id + '번 : 당첨금 초과 - 예상 적중금은 1,000만원을 초과할 수 없습니다.');
      return false;
    }
    return true;
  }

  function requestPrint(ticket, selectedTicketData) {
    // <-- 지점에서 처리상태 변경 (출력) -->
    if (!accountInfo.usePrinting()) {
      toast.error("웹에서는 프린트를 하실수 없습니다.");
      return;
    }

    let print_data = [];
    let currentTicket;
    let currentData;
    let visit_date = new Date();

    for (let id of ticket) {
      currentTicket = selectedTicketData[id];

      currentData = {
        order_id: id,
        purchase_amt: currentTicket.amount,
        username: currentTicket.registerName.split('|')[0].trim(),
        limit_allot: currentTicket.selectGame.length,
        visit_date: visit_date,
        matches: []
      };

      for (let game of currentTicket.selectGame) {
        currentData.matches.push({
          match_seq: getMatchSeqFromAllotId(game.allotId),
          bet_type: game.prediction === '승' ? 'win' : (game.prediction === '패' ? 'lose' : 'draw'),
          home_name: game.homeTeam.split(' ')[0].trim()
        });
      }
      print_data.push(currentData);
    }
    let msg = JSON.stringify({data: print_data, command: 'request_print'});

    window.chrome.webview.postMessage(msg);
  }

  const changeGameStatePrintDone = (ticket) => {
    // 지점에서 처리상태 변경 (인쇄완료)
    getFetcher().post(CHANGE_GAME_STATE_TO_PRINT_DONE_API, {
      user: accountInfo.makeJson(),
      gameDataIdList: ticket,
    }).then((res) => {
      const header = res.data.header;
      if (header.type === "success") {
        toast.success(header.message);
      } else {
        toast.error("변경이 실패하였습니다.");
      }
      setIsRequesting(false);
    }).catch(err => toast.error(err.message));
  }
  const changeGameStateAbort = (ticket) => {
    setIsRequesting(true);
    // <-- 지점에서 처리상태 변경 (보류) -->
    getFetcher().post(CHANGE_GAME_STATE_TO_ABORT_API, {
      user: accountInfo.makeJson(),
      gameDataIdList: ticket,
    }).then((res) => {
      const header = res.data.header;
      if (header.type === "success") {
        customToast.success("request", "보류 요청", header.message);
      } else {
        toast.error("변경이 실패하였습니다.");
      }
      setIsRequesting(false);
    }).catch(err => toast.error(err.message));
  }

  const changeGameStateCancel = (ticket, descriptionOfCancelGameTicket) => {
    setIsRequesting(true);
    // <-- 지점에서 처리상태 변경 (취소) -->
    getFetcher().post(CHANGE_GAME_STATE_TO_CANCEL_API, {
      user: accountInfo.makeJson(),
      gameDataIdList: ticket,
      des: descriptionOfCancelGameTicket
    }).then((res) => {
      const header = res.data.header;
      if (header.type === "success") {
        customToast.success("request", "취소 요청", header.message);
      } else {
        toast.error("변경이 실패하였습니다.");
      }
      setIsRequesting(false);
    }).catch(err => toast.error(err.message));
  }

  function sumCounts(message, failCount) {
    // <-- 정규식 패턴 정의 -->
    const pattern = /총 요청 : (\d+)건\n요청 성공 : (\d+)건\n요청 실패 : (\d+)건/;
    // 문자열에서 매칭되는 부분을 추출
    const matches = message.match(pattern);
    // 추출된 매칭이 없으면 null 반환
    if (!matches) {
      return message;
    }

    // 요청 건수와 실패 건수를 추출
    let totalRequests = parseInt(matches[1], 10);
    let successfulRequests = parseInt(matches[2], 10);
    let failedRequests = parseInt(matches[3], 10);

    // 합산된 값 반환
    return message.replace("총 요청 : " + totalRequests + "건", "총 요청 : " + (totalRequests + failCount) + "건")
        .replace("요청 실패 : " + failedRequests + "건", "요청 실패 : " + (failedRequests + failCount) + "건");
  }

  function addErrorMessage(message, errorList) {
    // <-- 프론트 에러메세지 + 백엔드 에러메세지 합치기 -->
    if (errorList.length === 0)
      return message;
    message = sumCounts(message, errorList.length);
    for (let e of errorList) {
      message += '\n' + e;
    }
    return message.replace("정상적으로 처리되었습니다.", "");
  }

  const changeGameStateRequest = (ticket, ticketJson) => {
    // <-- 지점에서 처리상태 변경 (요청) -->
    let errorList = [];

    ticket = ticket.filter((ticketId) => validateTicketAmount(errorList, ticketJson[ticketId]));

    if (errorList.length > 0) {
      console.log('CHANGE_GAME_STATE_TO_REQUEST_API:errorList->', errorList);
    }
    if (ticket.length === 0) {
      let message = "CHANGE_GAME_STATE_TO_REQUEST_API 처리 실패되었습니다.\n" + "총 요청 : 0건\n" + "요청 성공 : 0건\n" + "요청 실패 : 0건\n";
      message = addErrorMessage(message, errorList);
      customToast.success("request", "보나스포츠 의뢰 요청", message);
      return;
    }
    setIsRequesting(true);

    getFetcher().post(CHANGE_GAME_STATE_TO_REQUEST_API, {
      user: accountInfo.makeJson(),
      gameDataIdList: ticket,
      exName: 'bona',
    }).then((res) => {
      const header = res.data.header;
      if (header.type === "success") {
        let message = header.message;
        message = addErrorMessage(message, errorList);
        customToast.success("request", "보나스포츠 의뢰 요청", message);
      } else {
        toast.error("변경이 실패하였습니다.");
      }
      setIsRequesting(false);
    }).catch(err => toast.error(err.message));
  }

  const changeGameStateRequestCancel = (ticket) => {
    setIsRequesting(true);
    // <-- 지점에서 처리상태 변경 (의뢰취소) -->
    getFetcher().post(CHANGE_GAME_STATE_TO_REQUEST_CANCEL_API, {
      user: accountInfo.makeJson(),
      gameDataIdList: ticket,
      exName: 'bona',
    }).then((res) => {
      const header = res.data.header;
      if (header.type === "success") {
        customToast.success("request", "요청 취소", header.message);
      } else {
        toast.error("변경이 실패하였습니다.");
      }
      setIsRequesting(false);
    }).catch(err => toast.error(err.message));
  }
  const ticketExport = (ticket) => {
    if (!accountInfo.usePrinting()) {
      toast.error("웹에서는 하실수 없습니다.");
      return;
    }
    let msg = JSON.stringify({keys: ticket, command: 'ticket_export'});
    window.chrome.webview.postMessage(msg);
  }
  const actionsJsonLevel1 = [
    {name: '출력*', color: 'green', onClick: requestPrint},
    {name: '보류', color: 'gray', onClick: changeGameStateAbort},
    {name: '취소', color: 'red', onClick: (ticket) => setRequestCancelModalOpen(ticket)},
    {
      name: '복사요청*', color: 'fuchsia', onClick: (ticket, selectedTicketData) => setShowCopyRequestModal({
        isOpen: true,
        ticket: selectedTicketData[ticket[0]]
      })
    },
    {name: '모아찍기', color: 'indigo', onClick: ticketExport}
  ]

  const actionsJsonLevel2 = [
    {name: '출력*', color: 'green', onClick: requestPrint},
    {name: '보류', color: 'gray', onClick: changeGameStateAbort},
    {name: '취소', color: 'red', onClick: (ticket) => setRequestCancelModalOpen(ticket)},
    {name: '요청*', color: 'blue', onClick: changeGameStateRequest},
    {name: '요청취소', color: 'orange', onClick: changeGameStateRequestCancel},
    {
      name: '복사요청*', color: 'fuchsia', onClick: (ticket, selectedTicketData) => {
        if (ticket.length > 1) {
          toast.warning('복사요청은 한장만 선택하세요.');
          return;
        }
        setShowCopyRequestModal({
          isOpen: true,
          ticket: selectedTicketData[ticket[0]]
        })
      }
    },
    {name: '모아찍기', color: 'indigo', onClick: ticketExport}
  ]

  const ticketState = 'all▼!cart';
  const ticketOrderType = {
    view: ["stateDetail", "register", "charger-memo-only"],
    options: ["fixed", "copy-selectable", "memo-store-editable"]
  };
  const fixedFilter = {
    searchCondition: [{targetCode: '?'}]
  };
  const requestStateLevel1 = [
    {name: "의뢰상태 전체", code: "all"},
    {name: "의뢰", code: "order▼*!abort▼*!hold"},
    {name: "출력", code: "*print▼*!abort▼*!hold"},
    {name: "보류", code: "*hold"},
    {name: "취소", code: "*abort"},
  ]
  const requestStateLevel2 = [
    {name: "의뢰상태 전체", code: "all"},
    {name: "의뢰", code: "order▼*!abort▼*!hold"},
    {name: "출력", code: "*print▼*!abort▼*!hold"},
    {name: "보류", code: "*hold"},
    {name: "취소", code: "*abort"},
    {name: "요청", code: "request▼*!done▼*!abort"},
    // {name: "요청승인", code: "request▼*done"},
    {name: "요청취소", code: "request▼*abort"},
  ]

  const searchInputOption = [
    {name: "전체", code: "all"},
    {name: "의뢰번호", code: "request_number"},
    {name: "경기번호", code: "game_number"},
    {name: "닉네임", code: "nick_name"},
    {name: "메모", code: "memo"},
  ]
  return (
      <>
        {showCopyRequestModal.isOpen && <CopyTicketRequestModal ticketData={showCopyRequestModal.ticket}
                                                                ticketOrderType={ticketOrderType}
                                                                onClose={(res) => {
                                                                  setShowCopyRequestModal({
                                                                    isOpen: false,
                                                                    ticket: []
                                                                  })
                                                                  if (res) setRefreshDate(new Date());
                                                                }}/>
        }
        {/*<ModalHandler path={STORE_REQUEST_LIST_PAGE} initModalHandle={OpenModal}/>*/}
        {(requestCancelModalOpen && requestCancelModalOpen.length > 0) &&
            <ChangeGameStateCancelModal changeGameStateCancel={(description) => {
              changeGameStateCancel(requestCancelModalOpen, description);
            }
            } setRequestCancelModalOpen={setRequestCancelModalOpen}/>}
        <Sidebar/>

        <BaseTicketListPage listKey="store_request1" isRequesting={isRequesting} isShowFilter={true}
                            actionsJson={accountInfo.getStoreLevel() > 1 ? actionsJsonLevel2 : actionsJsonLevel1}
                            searchInputOption={searchInputOption}
                            ticketOrderType={ticketOrderType}
                            ticketState={ticketState} fixedFilter={fixedFilter}
                            requestState={accountInfo.getStoreLevel() > 1 ? requestStateLevel2 : requestStateLevel1}
                            refreshDate={refreshDate}
        />
      </>
  )
}
export default StoreRequestListPage;