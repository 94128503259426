import {Fragment, useState} from 'react'
import {Dialog, Transition} from '@headlessui/react'
import {CheckIcon} from '@heroicons/react/24/outline'
import neverLandUtils from "../../utils/NeverLandUtils";
import {RiInformationFill} from "react-icons/ri";
import bonaImage from "../../assets/images/bona_sports_info.png";

const RequestNoticeModal = ({setIsConfirmModalOpen, displayMessage}) => {
  const [open, setOpen] = useState(true);
  const [customInput, setCustomInput] = useState({});
  const [currentDisplayMessage, setCurrentDisplayMessage] = useState(displayMessage.shift());
  const [showBonaInfoImage, setShowBonaInfoImage] = useState(false);

  const closeModal = () => {
    if (displayMessage.length > 0) {
      setCurrentDisplayMessage(displayMessage.shift());
      return;
    }
    setOpen(false);
    setIsConfirmModalOpen(false);
  }

  let message = currentDisplayMessage.detail.split('\n');


  return (
      <Transition.Root show={open} as={Fragment}>
        <Dialog as="div" className="relative z-10" onClose={closeModal}>
          <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0"
              enterTo="opacity-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
          >
            <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity"/>
          </Transition.Child>

          <div className="fixed inset-0 z-10 w-screen overflow-y-auto">
            <div className="flex min-h-full items-center justify-center p-4 text-center sm:items-center sm:p-0">
              <Transition.Child
                  as={Fragment}
                  enter="ease-out duration-300"
                  enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                  enterTo="opacity-100 translate-y-0 sm:scale-100"
                  leave="ease-in duration-200"
                  leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                  leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              >
                <Dialog.Panel
                    className="relative transform overflow-wrap rounded-lg bg-white px-4 pb-4 pt-5 text-left shadow-xl transition-all sm:my-8 sm:p-6 sm:min-w-[30rem] sm:max-w-[35rem]">
                  <div>
                    <div className="flex flex-row ml-10">
                      <div className="mx-auto flex h-12 w-12 items-center justify-center rounded-full bg-slate-100">
                        <CheckIcon className="h-6 w-6 text-slate-600"/>
                      </div>

                      <button onClick={() => setShowBonaInfoImage(prevState => !prevState)}>
                        <RiInformationFill className="h-10 w-10 text-amber-500 relative"/>
                      </button>

                      {showBonaInfoImage &&
                          <img src={bonaImage} alt="bona_info" className="absolute right-1 top-16"
                               onClick={() => setShowBonaInfoImage(false)}/>
                      }
                    </div>

                    <div className="mt-3">
                      <Dialog.Title as="h3" className="text-xl font-semibold leading-6 text-center text-slate-900">
                        {currentDisplayMessage.title}
                      </Dialog.Title>
                      {currentDisplayMessage.type.includes("request") ?
                          <div>
                            <div className="mt-4 felx flex-col justify-start">
                              {message.length > 1 && <p className="font-semibold"> {message[1]}</p>}
                              {message.length > 2 && <p className="text-green-600"> {message[2]}</p>}
                              {message.length > 3 && <p className="text-red-600"> {message[3]}</p>}
                            </div>

                            <div className="mt-5 flex flex-col">
                              <p className="font-semibold">실패사유</p>
                              {message.length > 4 ?
                                  message.map((m) => {
                                    if (m.endsWith("처리 실패되었습니다.")) {
                                      return null;
                                    }
                                    if (m.endsWith("건")) {
                                      return null;
                                    }
                                    let thisMessage = m;
                                    thisMessage = thisMessage.split('- 기존상태 :')[0];
                                    return <p className="text-sm"> {thisMessage} </p>;
                                  })
                                  : <p> 실패 건이 없습니다. </p>
                              }

                            </div>
                          </div>
                          :
                          <div className="mt-4 felx flex-col justify-start">
                            {message.map((m) => {
                              if (m.length === 0)
                                return <br/>;
                              return <p className="text-sm"> {m} </p>;
                            })
                            }
                          </div>
                      }
                    </div>
                  </div>
                  <div className="mt-5 sm:mt-6">
                    {currentDisplayMessage.input &&
                        <div>
                          {currentDisplayMessage.input.map((text) => {
                            if (text.endsWith("*"))
                              return <input placeholder={text.replace('*', '')}
                                            onChange={(e) => setCustomInput({...customInput, [text]: e.target.value})}
                                            className="w-full rounded-md border-0 py-1.5 pl-2 text-gray-900 ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 sm:leading-6"
                              />
                            return <div className="flex flex-row justify-center mt-3 items-center w-full">
                              <p className="mr-3 whitespace-nowrap font-semibold"> {text} </p>
                              <input onChange={(e) => setCustomInput({...customInput, [text]: e.target.value})}
                                     className="w-full rounded-md border-0 py-1.5 pl-2 text-gray-900 ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 sm:leading-6"
                              />
                            </div>
                          })}
                        </div>
                    }
                  </div>
                  <div className="mt-5 sm:mt-6">
                    {currentDisplayMessage.button ?
                        <div>
                          {currentDisplayMessage.button.map((text) => {
                            return <button
                                type="button"
                                className={neverLandUtils.classNames((text.includes("취소") || text.includes("중지")) ? "bg-rose-600 hover:bg-rose-500" : "bg-blue-700 hover:bg-blue-600",
                                    "mt-2 inline-flex w-full justify-center  rounded-md px-3 py-2 text-sm font-semibold text-white shadow-sm"
                                )}
                                onClick={() => {
                                  if (currentDisplayMessage.onClick) {
                                    if (currentDisplayMessage.input && currentDisplayMessage.input.length > 0) {
                                      currentDisplayMessage.onClick(text, customInput);
                                    } else {
                                      currentDisplayMessage.onClick(text);
                                    }
                                  }
                                  closeModal();
                                }}
                            >
                              {text}
                            </button>
                          })}
                        </div>
                        :
                        <button
                            type="button"
                            className="inline-flex w-full justify-center rounded-md bg-slate-700 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-slate-600 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-green-600"
                            onClick={closeModal}
                        >
                          닫기
                        </button>
                    }
                  </div>
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </Dialog>
      </Transition.Root>
  )
}
export default RequestNoticeModal;