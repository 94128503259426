import {Disclosure, Transition} from '@headlessui/react'
import {MinusIcon, PlusIcon} from '@heroicons/react/24/outline'
import Sidebar from "../components/Sidebar";
import {useEffect, useState} from "react";
import {getFetcher} from "../utils/fetcher";
import {GET_BOARD_DETAIL_API, GET_BOARD_LIST_API} from "../constants/api_constants";
import {accountInfo} from "../constants/constants";
import {toast} from "react-toastify";
import React from 'react';

const FAQPage = () => {
  const [title, setTitle] = useState([]);
  const [content, setContent] = useState({});

  useEffect(() => {
    getQnATitle();
  }, []);

  const getQnATitle = () => {
    // <-- 자주 묻는 질문 제목 가져오기 -->
    getFetcher().get(accountInfo.makeGetRequest(GET_BOARD_LIST_API) + `&searchCondition='question-and-answer'&pageNumber=${1}&pageSize=${100}`)
        .then(res => {
          const type = res.data.header.type;
          if (type === 'fail') {
            console.log('불러오기 실패');
          } else if (type === 'success') {
            setTitle(res.data.result.boardList.content);
          }
        })
        .catch(err => console.log(err));
  }

  const getQnAContent = (boardId) => {
    // <-- 선택한 질문 내용 가져오기 -->
    getFetcher().get(accountInfo.makeGetRequest(GET_BOARD_DETAIL_API) + `&boardId=${boardId}`)
        .then((res) => {
          const type = res.data.header.type;
          if (type === 'fail') {
            toast.error('데이터 읽어오기 실패');
          } else if (type === 'success') {
            let newContentsList = {data: {}};
            if (content && content.data)
              newContentsList.data = content.data;
            if (!newContentsList.data[boardId]) {
              newContentsList.data[boardId] = {};
            }
            newContentsList.data[boardId].detail = res.data.result.boardContentDetail.content;
            setContent(newContentsList);
          }
        })
        .catch((err) => toast.error(err));
  }

  const TextFormatter = ({ text }) => {
    const tTagRegex = /<t>(.*?)<\/t>/g;

    const formatText = (inputText) => {
        return inputText.split('<br>').map((line, index) => (
            <p className="ml-3 sm:ml-10 mt-4 text-gray-600" key={index}>
                {line.split('<t>').map((part, partIndex) => {
                    if(partIndex % 2 === 1) {
                        return <React.Fragment key={partIndex}><span>&nbsp;</span>{part}</React.Fragment>;
                    }
                    return part;
                })}
            </p>
        ));
    };

    return formatText(text);
  };


  return (
      <>
        <Sidebar/>
        <div className="lg:pl-64">
          <div className="sm:px-4 lg:px-8">
            <div className="mx-auto max-w-6xl px-6 sm:py-20 lg:px-8">
              <div className="sm:px-4 lg:px-8">
                <div className="mx-auto divide-y divide-gray-400">
                  <div className="sm:flex-auto">
                    <h1 className="mt-5 sm:mt-0 text-2xl font-semibold leading-6 text-gray-900">
                      FAQ 자주 묻는 질문
                    </h1>
                    <p className="mt-3 text-sm text-gray-400">
                      ※ 그 외 기타문의 사항은 1:1문의 및 대표전화 (1577-9334) 를 이용해주세요.
                    </p>
                  </div>

                  <dl className="mt-5 space-y-3 divide-y divide-gray-300">
                    {title?.map((data) => (
                        <Disclosure as="div" key={data.boardId} className="pt-3">
                          {({open}) => (
                              <div className="text-sm">
                                <dt>
                                  <Disclosure.Button
                                      onClick={() => getQnAContent(data?.boardId)}
                                      className="bg-gray-100 py-2 px-5 sm:px-10 flex w-full items-start justify-between text-left pcSizeHover:hover:bg-gray-200">
                                    <span className="font-semibold">
                                      {data?.title}
                                    </span>
                                    <span className="ml-6 flex h-4 items-center">
                                    {open ?
                                        (<MinusIcon className="h-4 w-4"/>)
                                        : (<PlusIcon className="h-4 w-4"/>)
                                    }
                                    </span>
                                  </Disclosure.Button>
                                </dt>
                                <Transition
                                    show={open}
                                    enter="transition ease-out duration-300"
                                    enterFrom="opacity-0 -translate-y-2"
                                    enterTo="opacity-100 translate-y-0"
                                    leave="transition ease-in duration-200"
                                    leaveFrom="opacity-100 translate-y-0"
                                    leaveTo="opacity-0 -translate-y-2"
                                >
                                  <Disclosure.Panel as="dd" className="mt-2 pr-12">
                                    {content && content.data && content.data[data.boardId] &&
                                        // <p className="ml-3 sm:ml-10 mt-4 text-gray-600">
                                        //   {content?.data[data.boardId]?.detail}
                                        // </p>
                                        <TextFormatter text={content?.data[data.boardId]?.detail} />
                                    }
                                  </Disclosure.Panel>
                                </Transition>
                              </div>
                          )}
                        </Disclosure>
                    ))}
                  </dl>
                </div>
              </div>
            </div>
          </div>
        </div>
      </>
  )
}
export default FAQPage;
