import React, {Fragment, useEffect, useState} from 'react'
import {Dialog, Transition} from '@headlessui/react'
import {MOBYDICK_ADMIN_TEST_LIST_URL, MOBYDICK_MAIN_NOTICE_URL} from "../../constants/constants";
import neverLandUtils from "../../utils/NeverLandUtils";
import {RiMovieFill} from "react-icons/ri";
import ExternalLinkModal from "./ExternalLinkModal";

const MainNoticeModal = ({setShowAddModal}) => {
  const [open, setOpen] = useState(true);
  const [textData, setTextData] = useState('');
  const [externalLink, setExternalLink] = useState()
  const isLocalhost = window.location.hostname === 'localhost' || window.location.hostname.includes('192.168.1.');

  const closeModal = () => {
    setOpen(false);
    setShowAddModal(false);
  }

  const today = new Date().toISOString().split('T')[0]; // YYYY-MM-DD 형식으로 반환
  const saveDataToLocal = () => {
    const data = {
      isOpen: false,  // 띄우기 여부
      lastShownDate: today  // 닫은 날짜
    };

    // 로컬스토리지 저장
    neverLandUtils.commons.save('noti_modal', data);
    closeModal();
  }

  function checkTextDataDateTime(textData) {
    if (textData === null) return false;
    if (textData.length === 0) return false;
    if (textData[0] === '/') return false;

    const today = new Date().toISOString().split('T')[0]; // 오늘 날짜 YYYY-MM-DD 형식으로

    if (textData) {
      // 텍스트 데이터의 첫 번째 줄에서 날짜 추출
      const firstLine = textData.split('\n')[0].trim();

      // 첫 번째 줄에서 날짜가 있다면 비교
      if (firstLine) {
        if (firstLine <= today) {
          // 공지 날짜가 오늘 날짜보다 이전 또는 같은 경우 false
          return false;
        }
      }
      return true;
    }
  }

  function removeDateText(data) {
    // 텍스트 데이터에서 날짜 지우기
    const firstLine = data.split('\n')[0];
    return data.substring(firstLine.length).trim();
  }

  const getTextData = () => {
    let targetUrl = MOBYDICK_MAIN_NOTICE_URL;
    if (isLocalhost) {
      targetUrl = "http://teamneverland.com" + MOBYDICK_MAIN_NOTICE_URL + "?time=" + new Date().getTime();
    } else {
      targetUrl = targetUrl + "?" + new Date().getTime();
    }
    fetch(targetUrl).then((res) => res.text())
        .then((data) => {
          if (checkTextDataDateTime(data))
            setTextData(removeDateText(data));
          else closeModal();
        }).catch((err) => { closeModal(); })
  };

  useEffect(() => {
    getTextData();
  }, []);

  function popupLink(link) {
    setExternalLink(link);
    return true;
  }

  if (textData.length === 0) {
    return (<></>);
  }
  return (
      <>
        {externalLink ?
            <ExternalLinkModal title={"업데이트 내용보기"} externalLink={externalLink} setExternalLink={setExternalLink}/>
            :
            <Transition.Root show={open} as={Fragment}>
              <Dialog as="div" className="relative z-40" onClose={closeModal}>
                <Transition.Child
                    as={Fragment}
                    enter="ease-out duration-300"
                    enterFrom="opacity-0"
                    enterTo="opacity-100"
                    leave="ease-in duration-200"
                    leaveFrom="opacity-100"
                    leaveTo="opacity-0"
                >
                  <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity"/>
                </Transition.Child>

                <div className="fixed inset-0 z-40 w-screen overflow-y-auto">
                  <div className="flex min-h-full items-center justify-center p-4 text-center sm:items-center sm:p-0">
                    <Transition.Child
                        as={Fragment}
                        enter="ease-out duration-300"
                        enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                        enterTo="opacity-100 translate-y-0 sm:scale-100"
                        leave="ease-in duration-200"
                        leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                        leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                    >
                      <Dialog.Panel
                          className="relative transform overflow-hidden rounded-lg bg-white px-4 pb-4 pt-5 text-left shadow-xl transition-all m-10 w-[26rem]">

                        <div>
                          <p>{textData.split('\n').map((line, index) => {

                                if (line.includes("<link>")) {
                                  return (<span className="inline-block align-middle"
                                                onClick={() => {
                                                  popupLink(line.replace(/<\/?link>/g, ''))
                                                }}>
                          <RiMovieFill className="cursor-pointer h-6 w-auto text-red-600 ml-2"/>
                        </span>);
                                }
                                return (
                                    <span key={index}>
                         {index > 0 && <br/>} {line}
                        </span>
                                )
                              }
                          )}</p>
                        </div>

                        <div className="flex justify-end mt-10">
                          <button
                              className="m-3 font-semibold bg-gray-500 text-white text-sm py-1 px-3 rounded-lg text-md "
                              onClick={saveDataToLocal}
                          >
                            오늘 하루 열지 않기
                          </button>
                        </div>
                      </Dialog.Panel>
                    </Transition.Child>
                  </div>
                </div>
              </Dialog>
            </Transition.Root>
        }
      </>
  )
}

export default MainNoticeModal;
