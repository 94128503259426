import React, {Fragment, useEffect, useRef, useState} from 'react'
import {Dialog, Transition} from '@headlessui/react'
import {getFetcher} from "../../utils/fetcher";
import {accountInfo} from "../../constants/constants";
import {CUSTOMER_LIST_OF_MY_STORE_API, GET_MY_STORE_LIST_API} from "../../constants/api_constants";
import {toast} from "react-toastify";
import ListSelectBoxWithDefaultData from "../ListSelectBoxWithDefaultData";
import DatePicker from "react-datepicker";
import neverLandUtils from "../../utils/NeverLandUtils";

const RequestModal = ({requestModalData = {sent_ticket_list: []}, requestMethod, setRequestModalOpen}) => {
  const textareaRef = useRef(null);
  const [open, setOpen] = useState(true);
  const [visitTime, setVisitTime] = useState(new Date());
  const [requestMemo, setRequestMemo] = useState('');
  const [myStoreList, setMyStoreList] = useState([]);
  const [customerList, setCustomerList] = useState([]);
  const [selectedStore, setSelectedStore] = useState();
  const [selectedCustomer, setSelectedCustomer] = useState();

  const closeModal = () => {
    setOpen(false);
    setRequestModalOpen(false);
  }

  const sendRequestData = () => {
    let params = {
      visitTime: neverLandUtils.utils.getDisplayDateText(visitTime, true),
      requestMemo: requestMemo,
      selectedStore: selectedStore,
      customerUserIdForSpecialOrder: selectedCustomer ? selectedCustomer.data : -1
    };
    requestMethod(params);
    closeModal();
  }

  useEffect(() => {
    getMyCustomers();
    getMyStoreInfo();
    if (textareaRef.current) {
      textareaRef.current.focus();
    }
  }, []);

  function sortWithNumeric(newCustomerList) {
    newCustomerList.sort((a, b) => {
      // data 값이 -1인 경우 우선
      if (a.data === -1 && b.data !== -1) {
        return -1;
      }
      if (a.data !== -1 && b.data === -1) {
        return 1;
      }
      let nameA = a.name.split('|')[0];
      let nameB = b.name.split('|')[0];

      // 이름이 숫자인지 확인
      let isNumericA = !isNaN(nameA);
      let isNumericB = !isNaN(nameB);

      if (isNumericA && isNumericB) {
        // 둘 다 숫자면 숫자로 비교
        return Number(nameA) - Number(nameB);
      } else if (isNumericA) {
        // 숫자가 문자열보다 우선
        return -1;
      } else if (isNumericB) {
        // 숫자가 문자열보다 우선
        return 1;
      } else {
        // 둘 다 문자열이면 문자열로 비교
        return nameA.localeCompare(nameB);
      }
    });
  }

  const getMyCustomers = () => {
    if (!accountInfo.isSpecial()) {
      return;
    }
    if (!accountInfo.isStore()) {
      return;
    }
    getFetcher().get(CUSTOMER_LIST_OF_MY_STORE_API + `?ownerId=${accountInfo.accountId()}&searchCondition=registDate:2000/01/01-3000/12/31▼all:&pageNumber=1&pageSize=200&searchOrder=''`)
        .then(res => {
          const {header, result} = res.data;
          if (header.type === 'success') {
            let datalist = result.belongList.content;
            let newCustomerList = [];
            let index = 0;

            newCustomerList.push({id: index++, name: "※해당 없음", data: -1});
            for (let customer of datalist) {
              if (customer.state !== "confirmed")
                continue;
              newCustomerList.push({
                id: index++,
                name: (customer.name ? (customer.name + "|") : "") + customer.nickName,
                data: customer.userId
              });
            }
            sortWithNumeric(newCustomerList);
            setSelectedCustomer(newCustomerList[0]);
            setCustomerList(newCustomerList);
          }
        })
  }

  const getMyStoreInfo = () => {
    // 내 즐겨찾기 지점 정보 가져오기
    getFetcher().get(accountInfo.makeGetRequest(GET_MY_STORE_LIST_API))
        .then(res => {
          const storeList = res.data.result.myStoreList;

          let selectList = [];
          let index = 0;
          let defaultStore = null;
          let memo;

          for (let store of storeList) {
            if (store.state !== 'confirmed') continue; // 승인된 지점만 띄우기
            if (store.memo === null) {
              memo = store.tel;
            } else {
              memo = store.memo;
            }
            if (store.defaultStore) {
              selectList.push(defaultStore = {
                id: index++,
                name: store.name + ((memo && memo !== '-' && memo.length > 0) ? (" | " + memo) : "") + " (기본지점)",
                data: store
              });
            } else {
              selectList.push({
                id: index++,
                name: store.name + ((memo && memo !== '-' && memo.length > 0) ? (" | " + memo) : ""),
                data: store
              });
            }
          }

          if (selectList.length === 0) {
            // 등록된 지점이 없을 때 출력의뢰 못하게
            closeModal();
            toast.warn("등록된 지점이 없습니다. 지점코드를 확인하시어 추가해주세요");
            return;
          }
          setMyStoreList(selectList);

          if (defaultStore !== null) {
            // 기본지점이 있으면 기본지점을 기본선택으로 하고 없으면 처음 지점을 기본선택으로
            setSelectedStore(defaultStore);
          } else {
            setSelectedStore(selectList[0]);
          }
        });
  }

  let _requestCount = requestModalData.sent_ticket_list.length;
  return (
      <Transition.Root show={open} as={Fragment}>
        <Dialog as="div" className="relative z-10" onClose={closeModal}>
          <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0"
              enterTo="opacity-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
          >
            <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity"/>
          </Transition.Child>

          <div className="fixed inset-0 z-10 w-screen overflow-y-auto">
            <div className="flex min-h-full items-center justify-center p-4 text-center sm:items-center sm:p-0">
              <Transition.Child
                  as={Fragment}
                  enter="ease-out duration-300"
                  enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                  enterTo="opacity-100 translate-y-0 sm:scale-100"
                  leave="ease-in duration-200"
                  leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                  leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              >
                <Dialog.Panel
                    className="relative transform overflow-visible rounded-lg bg-white px-4 pb-4 pt-5 text-left shadow-xl transition-all sm:my-8 w-96 sm:max-w-lg sm:p-6">
                  <div>
                    <div className="my-5 text-center sm:mt-5">
                      <h3 className="text-xl font-semibold leading-6 text-gray-900">{_requestCount > 1 ? ("출력 의뢰 - 총 " + _requestCount + " 건") : "출력 의뢰"}</h3>
                    </div>
                  </div>

                  <div>
                    <div className="mt-5 flex flex-col text-sm">
                      <p className="mb-1">방문 예정 시각</p>
                      <DatePicker
                          selected={visitTime}
                          onChange={date => setVisitTime(date)}
                          showTimeSelect
                          timeFormat="HH:mm"
                          timeIntervals={15}
                          dateFormat="yyyy/MM/dd HH:mm:ss"
                          placeholderText="날짜와 시간을 선택해주세요"
                          className="w-full p-2 border border-gray-300 rounded-md"
                      />
                    </div>

                    <p className="mt-5 text-sm">출력의뢰 메모</p>
                    <textarea
                        ref={textareaRef}
                        autoFocus
                        spellCheck={false}
                        onChange={(e) => setRequestMemo(e.target.value)}
                        className="mt-1 px-4 w-full font-semibold rounded-md border-0 py-1.5 pl-2 text-gray-900 ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                    />

                    {customerList && customerList.length > 0 &&
                        <div className="mt-3 flex flex-col">
                          <p className="text-sm mr-3">**의뢰자</p>
                          <div className="mt-1">
                            <ListSelectBoxWithDefaultData defaultData={selectedCustomer} listData={customerList}
                                                          onChange={(selectOption) => setSelectedCustomer(selectOption)}/>
                          </div>
                        </div>
                    }
                    <div className="mt-3 flex flex-col">
                      <p className="text-sm mr-3">의뢰지점</p>
                      <div className="mt-1">
                        <ListSelectBoxWithDefaultData defaultData={selectedStore} listData={myStoreList}
                                                      onChange={(selectOption) => setSelectedStore(selectOption)}/>
                      </div>
                    </div>
                  </div>

                  <div className="mt-5 flex flex-row justify-center sm:mt-6 sm:grid-cols-2 sm:gap-3">
                    <button
                        type="button"
                        className="w-24 justify-center rounded-md bg-indigo-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600 sm:col-start-2"
                        onClick={sendRequestData}
                    >
                      출력의뢰
                    </button>
                    <button
                        type="button"
                        className="w-24 justify-center rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50 sm:col-start-1 sm:mt-0"
                        onClick={closeModal}
                    >
                      취소
                    </button>
                  </div>

                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </Dialog>
      </Transition.Root>
  )
}
export default RequestModal;
