import {Link} from "react-router-dom";
import {HEADERS} from "../../constants/component_data";
import {RiHeart2Fill, RiVipCrown2Fill} from "react-icons/ri";
import {accountInfo} from "../../constants/constants";

const AdminPageHeader = () => {
  return (
      <div className="lg:fixed top-0 w-full border-b bg-stone-900 z-20 shadow-md">
        <nav className="flex overflow-x-auto py-4">
          <ul
              role="list"
              className="flex min-w-full flex-none gap-x-10 px-4 text-sm font-semibold leading-6 text-gray-400 sm:px-6 lg:px-8"
          >
            {(accountInfo.isChain()? (accountInfo.getChainType()==='chain:watcher'? HEADERS.admin3HeaderNavigation: HEADERS.admin2HeaderNavigation):HEADERS.adminHeaderNavigation).map((item) =>
                  (!item.name.includes('ⓢ') ||(item.name.includes('ⓢ')&&accountInfo.getAdminLevel().includes("super"))) &&(
                <li key={item.name} className="flex flex-row items-center">
                  {item.name.includes('※') &&
                      <RiHeart2Fill className="h-4 w-auto mr-1 text-pink-600"/>
                  }
                  {item.name.includes('ⓢ') &&
                      <RiVipCrown2Fill className="h-4 w-auto mr-1 text-amber-300"/>
                  }
                  <Link to={item.link} className={item.current ? 'text-indigo-400' : ' hover:text-indigo-400'}>
                    {item.name.replace('※', '').replace('ⓢ', '')}
                  </Link>
                </li>
            ))}
          </ul>
        </nav>
      </div>
  )
}
export default AdminPageHeader;