import {RiArrowDownDoubleLine, RiArrowUpDoubleLine} from "react-icons/ri";
import React, {useEffect, useState} from "react";
import {useLocation} from "react-router-dom";

const getInitializeBottomState = () => {
  const scrollTop = document.documentElement.scrollTop || document.body.scrollTop;
  const scrollHeight = document.documentElement.scrollHeight;
  const clientHeight = document.documentElement.clientHeight;

  return scrollTop + clientHeight === scrollHeight;
}

const ScrollButton = () => {
  const [isTop, setIsTop] = useState(true);
  const [isBottom, setIsBottom] = useState(getInitializeBottomState());

  // 페이지 이동할때마다 버튼 깜빡거리는거 없애려면,, setTimeout 걸어줘야됨

  const handleScroll = () => {
    const scrollTop = document.documentElement.scrollTop || document.body.scrollTop;
    const scrollHeight = document.documentElement.scrollHeight;
    const clientHeight = document.documentElement.clientHeight;

    let _booleanIsTop = scrollTop === 0;
    let _booleanIsBottom = scrollTop + clientHeight === scrollHeight;
    // if (isTop !== _booleanIsTop)
    setIsTop(_booleanIsTop);
    // if(isBottom !== _booleanIsBottom)
    setIsBottom(_booleanIsBottom);
  };


  // 페이지 이동 할 때마다 스크롤 최상위 위치
  const {pathname} = useLocation();
  useEffect(() => {
    const scrollTop = document.documentElement.scrollTop || document.body.scrollTop;
    if (scrollTop !== 0)
      window.scrollTo(0, 0);
    else handleScroll();
  }, [pathname]);


  useEffect(() => {
    window.addEventListener('scroll', handleScroll);
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  const scrollToTop = () => {
    // 페이지 이동 할 때마다 스크롤 최상위 위치
    window.scrollTo({
      top: 0,
      behavior: 'smooth'
    });
  };

  const scrollToBottom = () => {
    window.scrollTo({
      top: document.documentElement.scrollHeight,
      behavior: 'smooth'
    });
  };

  return (
      <div className='absolute top-0 flex flex-col right-16 lg:left-0 lg:ml-[236px] ri-align-center'>
        <div className="z-[60] fixed lg:top-0 bottom-0 w-auto h-auto flex lg:mt-28 mb-20">
          {!isTop && (
              <button
                  onClick={scrollToTop}
                  className='bg-gray-900 bg-opacity-70 lg:bg-blue-600 h-12 w-10 text-white rounded-full px-1'>
                <RiArrowUpDoubleLine className='h-8 w-auto'/>
              </button>
          )}
        </div>

        <div className="z-[60] fixed bottom-0 w-auto h-auto flex lg:mb-28 mb-6">
          {!isBottom && (
              <button
                  onClick={scrollToBottom}
                  className='bg-gray-900 bg-opacity-70 lg:bg-blue-600 h-12 w-10 text-white rounded-full px-1'>
                <RiArrowDownDoubleLine className='h-8 w-auto'/>
              </button>
          )}
        </div>
      </div>
  )
}
export default ScrollButton