import Sidebar from "../../components/Sidebar";
import React, {useState} from "react";
import {accountInfo} from "../../constants/constants";
import neverLandUtils from "../../utils/NeverLandUtils";

const DeveloperManagementPage = () => {
  const [inputIP, setInputIP] = useState(null);

  const [developerSettings, setDeveloperSettings] = useState([
    {
      title: '개발자용 메뉴',
      options:
          [
            {name: "로컬 테스트 모드", isOn: false, key: 'on_local_test_mode', withDetailData: true}
          ]
    },
  ]);

  const toggleDeveloperSetting = (name, boolean) => {
    let updatedSettings = developerSettings;
    let settingIndex;
    let optionsIndex = -1;
    let tempIndex;

    settingIndex = updatedSettings.findIndex(list => {
      if (-1 !== (tempIndex = list.options.findIndex(opt => opt.name === name))) {
        optionsIndex = tempIndex;
        return true;
      }
      return false;
    });

    if (settingIndex !== -1 && optionsIndex !== -1) {
      let key = updatedSettings[settingIndex].options[optionsIndex].key;
      accountInfo.getUserConfig().setOptionOn(key, boolean);

      setDeveloperSettings([...updatedSettings]);
    }
  }

  return (
      <div>
        <Sidebar/>

        <main className="lg:pl-64 min-h-screen">
          {developerSettings.map((category) => {
            return (
                <div
                    className="grid max-w-full grid-cols-1 gap-x-8 gap-y-10 px-4 py-6 sm:py-16 sm:px-6 md:grid-cols-3 lg:px-8">

                  <div className="sm:ml-10">
                    <h2 className="text-xl font-semibold leading-7 text-gray-900">{category.title}</h2>
                  </div>

                  <div className="md:col-span-2">
                    <div className="gap-x-6 gap-y-8 sm:max-w-xl">
                      {category.options.map((option) => {
                        if (option.name.includes("ⓢ") && !accountInfo.isStore()) {
                          return <></>;
                        }
                        let isOptionOn = accountInfo.getUserConfig().isOn(option.key, option.isOn);
                        return (
                            <div>
                              <div
                                  className="flex flex-row whitespace-nowrap items-center mb-10 justify-between sm:pr-40">
                                <label htmlFor="nickname"
                                       className="block text-sm sm:text-lg font-semibold leading-6 text-gray-900">
                                  {option.name.replace("ⓢ", "")}
                                </label>
                                <div className="flex flex-row justify-center ring-2 ring-gray-900 rounded-xl">
                                  <button
                                      onClick={() => toggleDeveloperSetting(option.name, true)}
                                      type="button"
                                      className={neverLandUtils.classNames(isOptionOn ? "bg-gray-900 text-white" : "bg-white text-gray-900", "whitespace-nowrap rounded-xl px-2 py-1 text-xs font-semibold shadow-sm")}
                                  >
                                    ON
                                  </button>
                                  <button
                                      onClick={() => toggleDeveloperSetting(option.name, false)}
                                      type="button"
                                      className={neverLandUtils.classNames(isOptionOn ? "bg-white text-gray-900" : "bg-gray-900 text-white", "whitespace-nowrap rounded-xl px-1.5 py-1 text-xs font-semibold shadow-sm")}
                                  >
                                    OFF
                                  </button>
                                </div>
                              </div>

                              {option.withDetailData &&
                                  <div className="fle flex-row">
                                    <input
                                        onChange={(e) => setInputIP(e.target.value)}
                                        className="mt-1 px-4 w-52 font-semibold rounded-md border-0 py-1.5 pl-2 ring-1 ring-inset ring-gray-900"
                                    />
                                    <button
                                        onClick={() => neverLandUtils.commons.save('local_ip', inputIP)}
                                        className="ml-3 bg-gray-900 rounded-md px-3 py-2 text-sm font-semibold text-white shadow-sm"
                                    >
                                      IP 저장
                                    </button>
                                  </div>
                              }
                            </div>
                        )
                      })}
                    </div>
                  </div>
                </div>
            )
          })}
        </main>
      </div>
  )
}
export default DeveloperManagementPage;