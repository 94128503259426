import {ModalHandler, OpenModalHandle} from "../../components/Modal/Generic/ModalHandler";
import {MAIN_PAGE, STORE_MANAGEMENT_PAGE} from "../../constants/page_constants";
import Sidebar from "../../components/Sidebar";
import React, {useEffect, useState} from "react";
import ListSelectBox from "../../components/ListSelectBox";
import {getFetcher} from "../../utils/fetcher";
import {
  ADMIN_EDIT_STORE_INFO_API,
  ADMIN_GET_STORE_INFO_API,
  CONNECT_BONA_TO_STORE_API,
  CREATE_STORE_API, LOGIN_STORE_ACCOUNT_BY_ADMIN
} from "../../constants/api_constants";
import {toast} from "react-toastify";
import AdminPageHeader from "../../components/Header/AdminPageHeader";
import {Link, useNavigate, useParams} from "react-router-dom";
import {accountInfo} from "../../constants/constants";
import ExternalLinkModal from "../../components/Modal/ExternalLinkModal";
import neverLandUtils from "../../utils/NeverLandUtils";
import {storeLevelOption} from "../../constants/component_data";
import axios from "axios";
import {RiLoginCircleLine} from "react-icons/ri";


const StoreManagementPage = () => {
  // 모달 띄우기
  const OpenModal = OpenModalHandle.create();
  const [externalLink, setExternalLink] = useState('');
  const [storeName, setStoreName] = useState(''); // 지점명
  const [storeCode, setStoreCode] = useState(''); // 지점코드
  const [storeAddress, setStoreAddress] = useState(''); // 지점주소
  const [storeTell, setStoreTell] = useState(''); // 연락처
  const [storeLevel, setStoreLevel] = useState(storeLevelOption[0].id); // 승인레벨
  const [storeOwnerEmail, setStoreOwnerEmail] = useState('');
  const [originStoreOwnerEmail, setOriginStoreOwnerEmail] = useState('');
  const [bonaID, setBonaID] = useState(''); // 보나스포츠 ID
  const [isCheckedBonaID, setIsCheckedBonaID] = useState(false);

  let {paramStoreCode} = useParams();
  const navigate = useNavigate();


  // const linkToBona = (mailaddress) => {
  //   let url = 'https://bonasports.co.kr/auth/external/register';
  //
  //   url = 'https://bonasports.co.kr/mo-register?username=' + mailaddress;
  //   // 외부 링크를 새로운 주소로 설정
  //   setExternalLink(url);
  // };

  const linkToBona = (mailaddress) => {
    let url = 'https://bonasports.co.kr/auth/external/register';

    url = 'https://bonasports.co.kr/auth/register/check?username=' + mailaddress;
    url = 'https://bonasports.co.kr/auth/exregister';
    // setExternalLink(url);
    axios.post(url, {
      username: mailaddress,
      create_at: '2024-05-08 03:00:00',
      regist_info: {
        password: '1234',
        reference: '',
        limit_amt: 70000
      }
    }).then(res => {
      const data = res.data;
      console.log('data', data);
    }).catch(err => console.log(err));
  };


  const autoGenerationFromUserInformation = (info) => {
    let userName = info[0];
    let email = info[1];

    setStoreName(userName);
    setStoreCode(userName + "◁RANDOM");
    setStoreOwnerEmail(email);
    setStoreAddress("자동 등록")
    setStoreTell("-")
  }

  useEffect(() => {
    if (paramStoreCode) {
      if (paramStoreCode.includes("◆")) {
        autoGenerationFromUserInformation(paramStoreCode.replace("◆", "").split('▼'));
        paramStoreCode = null;
        return;
      }
      return getStoreInfo(paramStoreCode);
    }
    if (!paramStoreCode) clearInput();
  }, [paramStoreCode]);

  const clearInput = () => {
    setStoreName('');
    setStoreCode('');
    setStoreAddress('');
    setStoreTell('');
    setStoreOwnerEmail('');
    setStoreLevel(storeLevelOption[0].id);
  }

  const connectStoreToStoreOwner = (_storeCode) => {
    // <-- 지점에 점주 지정 -->
    let _storeEmail;
    if (storeOwnerEmail) {
      _storeEmail = storeOwnerEmail;
    }
    if (bonaID) {
      _storeEmail = bonaID;
    }
    if ((storeOwnerEmail && bonaID) && (storeOwnerEmail !== bonaID)) {
      toast.warn('점주 이메일과 보나ID는 동일해야 합니다.');
    }
    // let _encodeStoreCode = encodeURIComponent(_storeCode);
    // TODO : 나중에 백엔드 고치면 다시 인코딩 하는걸로 변경
    let _encodeStoreCode = decodeURIComponent(_storeCode);
    getFetcher().post(CONNECT_BONA_TO_STORE_API, {
      user: accountInfo.makeJson(),
      storeCode: _encodeStoreCode,
      email: _storeEmail
    }).then(res => {
      if (res.data.header.type === 'fail') {
        toast.error(res.data.header.message);
      }
      console.log(res.data);
    }).catch(err => {
      toast.error(err.message);
    })
  }

  const createStore = () => {
    // <-- 지점 생성 -->
    if (!storeTell) {
      toast.warn('연락처는 필수 사항 입니다.');
      return;
    }
    let _encodeStoreCode = encodeURIComponent(storeCode);
    getFetcher().post(CREATE_STORE_API, {
      user: accountInfo.makeJson(),
      storeCode: _encodeStoreCode,
      storeName: storeName,
      address: storeAddress,
      phoneNumber: storeTell,
      storeLevel: storeLevel
    })
        .then(res => {
          const {header, custom} = res.data;
          if (header.type === 'success') {
            toast.success('지점 등록이 완료되었습니다.');
            // 점주메일 또는 보나아이디 있을 경우 지점과 연결
            let _storeCode = _encodeStoreCode;
            if (custom) {
              for (let item of custom.items) {
                if (item.key === 'storeCode') {
                  _storeCode = item.value;
                }
              }
            }
            if (storeOwnerEmail) {
              connectStoreToStoreOwner(_storeCode);
            }
            clearInput();
          } else {
            toast.error(header.message);
          }
        })
  }

  const getStoreInfo = (paramStoreCode) => {
    let _encodeStoreCode = encodeURIComponent(paramStoreCode);
    // 관리자의 파라미터값으로 지점 정보 가져오기
    getFetcher().get(accountInfo.makeGetRequest(ADMIN_GET_STORE_INFO_API) + `&storeCode=${_encodeStoreCode}`)
        .then(res => {
          const {header, result} = res.data;
          if (header.type === 'success') {
            const storeInfo = result.storeInfoForOwner;
            setStoreName(storeInfo.storeName);
            setStoreCode(storeInfo.storeCode);
            setStoreAddress(storeInfo.address);
            setStoreTell(storeInfo.phoneNumber);
            setStoreLevel(Number(storeInfo.storeLevel));

            // 점주 이메일주소 가져오기
            const _storeOwnerEmail = storeInfo.additionalData;
            if (_storeOwnerEmail && _storeOwnerEmail.length > 0) {
              if (_storeOwnerEmail[0].value !== '-') {
                setStoreOwnerEmail(_storeOwnerEmail[0].value)
                setOriginStoreOwnerEmail(_storeOwnerEmail[0].value)
              } else {
                setStoreOwnerEmail(_storeOwnerEmail[1].value);
                setOriginStoreOwnerEmail(_storeOwnerEmail[1].value);
              }
            }
          } else {
            toast.error(header.message);
          }
        }).catch(err => toast.error(err.message));
  }

  const editStoreInfo = () => {
    // <-- 관리자의 지점 정보 수정 -->
    let encodeStoreCode = encodeURIComponent(storeCode);
    getFetcher().post(ADMIN_EDIT_STORE_INFO_API, {
      user: accountInfo.makeJson(),
      storeCode: encodeStoreCode,
      storeName: storeName,
      address: storeAddress,
      phoneNumber: storeTell,
      storeLevel: storeLevel,
      extUserCode: bonaID
    }).then(res => {
      const {type, message} = res.data.header;
      if (type === 'success') {
        toast.success(message);
        // 점주 메일 또는 보나아이디 있을 경우 지점과 연결
        // 점주 메일 변동 없으면 점주 메일 지정 함수 실행안함
        if (storeOwnerEmail && originStoreOwnerEmail !== storeOwnerEmail) {
          connectStoreToStoreOwner(encodeStoreCode);
        }
      } else {
        toast.error(message);
      }
    })
  }

  const loginStoreByAdmin = () => {
    accountInfo.saveMonitoringAdmin();
    // <-- 해당지점의 점주 계정으로 로그인하기 -->
    getFetcher().post(LOGIN_STORE_ACCOUNT_BY_ADMIN, {
      user: accountInfo.makeJson(),
      storeCode: storeCode
    })
        .then(res => {
          const {type, message} = res.data.header;
          if (type === 'fail') {
            toast.error(message);
            return;
          }
          if (type === 'success') {
            toast.success(message);
            let userInfo = res.data.result.user;
            accountInfo.login(userInfo);
            navigate(MAIN_PAGE);
          }
        })
        .catch((err) => toast.error(err));
  }

  function getStoreLevelItem(storeLevel) {
    let item = storeLevelOption.find((item) => item.id === storeLevel) || storeLevelOption[0];
    return item;
  }

  // 지점 생성인지 수정인지
  let isCreate = true;
  if (paramStoreCode) {
    if (!paramStoreCode.includes("◆"))
      isCreate = false;
  }
  return (
      <div>
        {externalLink && <ExternalLinkModal title={"보나스포츠 회원가입"} externalLink={externalLink} setExternalLink={setExternalLink}/>}
        <ModalHandler path={STORE_MANAGEMENT_PAGE} initModalHandle={OpenModal}/>
        <Sidebar/>
        <div className="lg:pl-64">
          <AdminPageHeader/>

          <main>
            <div className="lg:mt-20  divide-y divide-gray-300">
              <div
                  className="grid max-w-full grid-cols-1 gap-x-8 gap-y-10 px-4 py-6 sm:py-16 sm:px-6 md:grid-cols-3 lg:px-8">
                <div className="sm:ml-10">
                  <h2 className="text-xl font-semibold text-gray-900">지점관리</h2>
                  <p className="mt-1 text-sm leading-6 text-gray-400">
                    {isCreate ? "지점 신규등록" : "기존 지점 수정"}
                  </p>
                  {!isCreate && <button className="mt-3 bg-gray-200 px-2.5 py-1.5 rounded-xl text-sm">
                    <Link to={STORE_MANAGEMENT_PAGE}>
                      지점 신규등록 하기
                    </Link>
                  </button>
                  }
                </div>

                <div className="md:col-span-2">
                  <div className="grid grid-cols-2 gap-x-6 gap-y-8 sm:max-w-xl sm:grid-cols-6">

                    <div className="sm:flex col-span-4">
                      <div className="sm:col-span-2">
                        <label htmlFor="storeName" className="block text-sm font-semibold leading-6 text-gray-900">
                          지점명
                        </label>
                        <div className="mt-2">
                          <input
                              id="storeName"
                              value={storeName || ''}
                              onChange={(e) => setStoreName(e.target.value)}
                              className="block w-full rounded-md border-0 py-1.5 pl-3 text-gray-900 ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                          />
                        </div>
                      </div>

                      <div className="mt-8 sm:mt-0 sm:pl-4 sm:col-span-2">
                        <label htmlFor="storeCode" className="block text-sm font-semibold leading-6 text-gray-900">
                          지점코드
                        </label>
                        <div className="mt-2">
                          <input
                              id="storeCode"
                              value={storeCode || ''}
                              onChange={(e) => setStoreCode(e.target.value)}
                              className="text-gray-900 block w-full rounded-md border-0 py-1.5 pl-3 ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                          />
                        </div>
                      </div>
                    </div>

                    <div className="col-span-4">
                      <label htmlFor="email" className="block text-sm font-semibold leading-6 text-gray-900">
                        지점주소
                      </label>
                      <div className="mt-2">
                        <input
                            value={storeAddress || ''}
                            onChange={(e) => setStoreAddress(e.target.value)}
                            className="block w-full rounded-md border-0 py-1.5 pl-3 text-gray-900 ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                        />
                      </div>
                    </div>

                    <div className="col-span-4">
                      <label htmlFor="phone-number" className="block text-sm font-semibold leading-6 text-gray-900">
                        연락처
                      </label>
                      <div className="mt-2">
                        <input
                            value={storeTell || ''}
                            onChange={(e) => setStoreTell(e.target.value)}
                            className="block w-full rounded-md border-0 py-1.5 pl-3 text-gray-900 ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                        />
                      </div>
                    </div>

                    <div className="col-span-4">
                      <label htmlFor="email" className="block text-sm font-semibold leading-6 text-gray-900 mb-2">
                        승인레벨
                      </label>
                      <ListSelectBox defaultValue={getStoreLevelItem(storeLevel)} listData={storeLevelOption}
                                     onChange={(e) => setStoreLevel(e.id)}/>
                    </div>

                    <div className="col-span-4">
                      <label htmlFor="phone-number" className="block text-sm font-semibold leading-6 text-gray-900">
                        점주 이메일
                      </label>
                      <div className="mt-2 flex flex-row">
                        <input
                            value={storeOwnerEmail || ''}
                            onChange={(e) => setStoreOwnerEmail(e.target.value)}
                            className={neverLandUtils.classNames(storeOwnerEmail ? '' : 'bg-red-50 ring-red-200', 'block w-full rounded-md border-0 py-1.5 pl-3 text-gray-900 ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6')}
                        />
                        {!isCreate && storeOwnerEmail && <button onClick={loginStoreByAdmin} title="이 계정으로 로그인">
                          <RiLoginCircleLine className='bg-emerald-600 h-10 w-10 text-white rounded-full px-2 ml-3'/>
                        </button>
                        }
                      </div>
                    </div>

                    <div className="col-span-4">
                      <div className="flex flex-row">
                        <input type="checkbox" checked={isCheckedBonaID}
                               onClick={() => {
                                 if (!isCheckedBonaID && storeOwnerEmail !== '-' && storeOwnerEmail !== '') {
                                   setBonaID(storeOwnerEmail);
                                 }
                                 setIsCheckedBonaID(!isCheckedBonaID)
                               }}/>
                        <label htmlFor="email" className="block text-sm font-semibold leading-6 text-gray-900 ml-2">
                          보나스포츠 ID
                        </label>
                      </div>
                      <div className="mt-2">
                        <input
                            disabled={!isCheckedBonaID}
                            onChange={(e) => setBonaID(e.target.value)}
                            value={bonaID || ''}
                            className={neverLandUtils.classNames((!isCheckedBonaID && !bonaID) ? '' : 'bg-red-50 ring-red-200', 'block w-full rounded-md border-0 py-1.5 pl-3 text-gray-900 ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6')}
                        />
                      </div>
                      <button onClick={() => {
                        if (bonaID && isCheckedBonaID) linkToBona(bonaID); else toast.error("이메일 형식의 아이디를 등록하셔야됩니다.")
                      }} className="text-sm mt-1"> ▷ 보나 회원 가입하기
                      </button>
                    </div>
                  </div>

                  <div className="mt-8 flex">
                    {!isCreate ?
                        <button
                            className="rounded-md bg-sky-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-sky-500"
                            onClick={editStoreInfo}
                        >
                          [{storeName || ''}] 지점 수정
                        </button>
                        :
                        <button
                            className="rounded-md bg-sky-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-sky-500"
                            onClick={createStore}
                        >
                          신규 지점 등록
                        </button>
                    }
                    {!isCreate && <button
                        className="rounded-md ml-3 bg-rose-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-rose-500">
                      삭제
                    </button>
                    }
                  </div>
                </div>
              </div>
            </div>
          </main>
        </div>
      </div>
  )
}
export default StoreManagementPage;