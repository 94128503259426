import React, {useEffect, useState} from "react";
import {getFetcher} from "../../utils/fetcher";
import {accountInfo} from "../../constants/constants";
import {AGGREGATE_ITEM_API} from "../../constants/api_constants";
import {toast} from "react-toastify";
import TicketCardModal from "../../components/Modal/TicketCardModal";
import BaseTicketCard from "../../components/Card/BaseTicketCard";
import {useParams} from "react-router-dom";


const TicketPrintPage = () => {
  const {requestNumber} = useParams();
  const [ticketModalData, setTicketModalData] = useState({isOpen: false});
  useEffect(() => {
    showTicketModal(requestNumber);
  }, []);

  function showTicketModal(requestNumber) {
    // <-- 폴더버튼 선택 시 티켓 모달띄우기 -->
    getFetcher().get(accountInfo.makeGetRequest(AGGREGATE_ITEM_API) + `&id=${requestNumber}`)
        .then(res => {
          const type = res.data.header.type;
          if (type === 'fail') {
            console.log('불러오기 실패');
          } else if (type === 'success') {
            setTicketModalData({isOpen: true, data: res.data.result.data});
          }
        }).catch(err => toast.error(err));
  }

  return (
      <>
        {ticketModalData.isOpen && <BaseTicketCard gameDataList={[ticketModalData.data]} isExport={true}/>}
      </>
  )
}
export default TicketPrintPage;
