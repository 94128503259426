import neverLandGameData from "./NeverLandGameData";

let _useDummyData = false;

function loadDummyData() {
  if (_useDummyData) {
    _useDummyData = false;
    neverLandGameData.gameData = [{
      "leagueName": "A리그",
      "dataId": "bd_G101_240005_0000",
      "data": [{
        "lose": "4.1",
        "draw": "3.95",
        "extraText": "무",
        "allotId": "bd_G101_240005_0000_0001",
        "win": "1.53",
        "gameNo": "1"
      }, {
        "lose": "2.35",
        "draw": "-1.0",
        "extraText": "H",
        "allotId": "bd_G101_240005_0000_0002",
        "win": "2.22",
        "gameNo": "2"
      }, {
        "lose": "1.75",
        "draw": "3.5",
        "extraText": "U/O",
        "allotId": "bd_G101_240005_0000_0003",
        "win": "1.77",
        "gameNo": "3"
      }],
      "awayTeam": "맥아서FC",
      "homeTeam": "애들유나",
      "state": "게임종료",
      "closingDate": "2024-01-08 17:35:00"
    }, {
      "leagueName": "KBL",
      "dataId": "bd_G101_240005_0001",
      "data": [{
        "lose": "2.34",
        "draw": "0.0",
        "extraText": "null",
        "allotId": "bd_G101_240005_0001_0004",
        "win": "1.41",
        "gameNo": "4ⓢ"
      }, {
        "lose": "3.85",
        "draw": "2.55",
        "extraText": "⑤",
        "allotId": "bd_G101_240005_0001_0005",
        "win": "2.01",
        "gameNo": "5ⓢ"
      }, {
        "lose": "1.73",
        "draw": "-4.5",
        "extraText": "H",
        "allotId": "bd_G101_240005_0001_0006",
        "win": "1.79",
        "gameNo": "6ⓢ"
      }, {
        "lose": "1.71",
        "draw": "170.5",
        "extraText": "U/O",
        "allotId": "bd_G101_240005_0001_0007",
        "win": "1.81",
        "gameNo": "7ⓢ"
      }],
      "awayTeam": "KT소닉붐",
      "homeTeam": "원주DB",
      "state": "게임종료",
      "closingDate": "2024-01-08 18:50:00"
    }, {
      "leagueName": "KBL",
      "dataId": "bd_G101_240005_0002",
      "data": [{
        "lose": "3.3",
        "draw": "0.0",
        "extraText": "null",
        "allotId": "bd_G101_240005_0002_0008",
        "win": "1.2",
        "gameNo": "8ⓢ"
      }, {
        "lose": "6.4",
        "draw": "2.95",
        "extraText": "⑤",
        "allotId": "bd_G101_240005_0002_0009",
        "win": "1.53",
        "gameNo": "9ⓢ"
      }, {
        "lose": "1.77",
        "draw": "-8.5",
        "extraText": "H",
        "allotId": "bd_G101_240005_0002_0010",
        "win": "1.75",
        "gameNo": "10ⓢ"
      }, {
        "lose": "1.79",
        "draw": "164.5",
        "extraText": "U/O",
        "allotId": "bd_G101_240005_0002_0011",
        "win": "1.73",
        "gameNo": "11ⓢ"
      }],
      "awayTeam": "서울삼성",
      "homeTeam": "한국가스",
      "state": "게임종료",
      "closingDate": "2024-01-08 18:50:00"
    }, {
      "leagueName": "남축INTL",
      "dataId": "bd_G101_240005_0003",
      "data": [{
        "lose": "1.05",
        "draw": "6.7",
        "extraText": "무",
        "allotId": "bd_G101_240005_0003_0012",
        "win": "21.0",
        "gameNo": "12"
      }, {
        "lose": "2.1",
        "draw": "2.0",
        "extraText": "H",
        "allotId": "bd_G101_240005_0003_0013",
        "win": "2.55",
        "gameNo": "13"
      }, {
        "lose": "3.46",
        "draw": "0.0",
        "extraText": "null",
        "allotId": "bd_G101_240005_0003_0014",
        "win": "1.18",
        "gameNo": "14"
      }, {
        "lose": "2.29",
        "draw": "3.5",
        "extraText": "U/O",
        "allotId": "bd_G101_240005_0003_0015",
        "win": "1.43",
        "gameNo": "15"
      }],
      "awayTeam": "이란",
      "homeTeam": "인도네시",
      "state": "발매중",
      "closingDate": "2024-01-09 21:50:00"
    }, {
      "leagueName": "남축INTL",
      "dataId": "bd_G101_240005_0004",
      "data": [{
        "lose": "2.3",
        "draw": "2.85",
        "extraText": "무",
        "allotId": "bd_G101_240005_0004_0016",
        "win": "2.75",
        "gameNo": "16"
      }, {
        "lose": "5.3",
        "draw": "1.0",
        "extraText": "H",
        "allotId": "bd_G101_240005_0004_0017",
        "win": "1.44",
        "gameNo": "17"
      }, {
        "lose": "2.4",
        "draw": "2.5",
        "extraText": "U/O",
        "allotId": "bd_G101_240005_0004_0018",
        "win": "1.39",
        "gameNo": "18"
      }],
      "awayTeam": "카메룬",
      "homeTeam": "잠비아",
      "state": "발매중",
      "closingDate": "2024-01-09 21:50:00"
    }, {
      "leagueName": "NBA",
      "dataId": "bd_G101_240005_0007",
      "data": [{
        "lose": "1.08",
        "draw": "0.0",
        "extraText": "null",
        "allotId": "bd_G101_240005_0007_0025",
        "win": "4.75",
        "gameNo": "25"
      }, {
        "lose": "1.81",
        "draw": "11.5",
        "extraText": "H",
        "allotId": "bd_G101_240005_0007_0026",
        "win": "1.71",
        "gameNo": "26"
      }, {
        "lose": "1.74",
        "draw": "245.5",
        "extraText": "U/O",
        "allotId": "bd_G101_240005_0007_0027",
        "win": "1.78",
        "gameNo": "27"
      }],
      "awayTeam": "오클썬더",
      "homeTeam": "워싱위저",
      "state": "게임종료",
      "closingDate": "2024-01-09 08:50:00"
    }, {
      "leagueName": "NBA",
      "dataId": "bd_G101_240005_0008",
      "data": [{
        "lose": "2.29",
        "draw": "0.0",
        "extraText": "null",
        "allotId": "bd_G101_240005_0008_0028",
        "win": "1.43",
        "gameNo": "28"
      }, {
        "lose": "1.74",
        "draw": "-4.5",
        "extraText": "H",
        "allotId": "bd_G101_240005_0008_0029",
        "win": "1.78",
        "gameNo": "29"
      }, {
        "lose": "1.74",
        "draw": "218.5",
        "extraText": "U/O",
        "allotId": "bd_G101_240005_0008_0030",
        "win": "1.78",
        "gameNo": "30"
      }],
      "awayTeam": "휴스로케",
      "homeTeam": "마이히트",
      "state": "게임종료",
      "closingDate": "2024-01-09 09:20:00"
    }, {
      "leagueName": "KBL",
      "dataId": "bd_G101_240005_0012",
      "data": [{
        "lose": "2.26",
        "draw": "0.0",
        "extraText": "null",
        "allotId": "bd_G101_240005_0012_0041",
        "win": "1.44",
        "gameNo": "41ⓢ"
      }, {
        "lose": "3.65",
        "draw": "2.65",
        "extraText": "⑤",
        "allotId": "bd_G101_240005_0012_0042",
        "win": "2.01",
        "gameNo": "42ⓢ"
      }, {
        "lose": "1.82",
        "draw": "-3.5",
        "extraText": "H",
        "allotId": "bd_G101_240005_0012_0043",
        "win": "1.7",
        "gameNo": "43ⓢ"
      }, {
        "lose": "1.73",
        "draw": "165.5",
        "extraText": "U/O",
        "allotId": "bd_G101_240005_0012_0044",
        "win": "1.79",
        "gameNo": "44ⓢ"
      }],
      "awayTeam": "울산모비",
      "homeTeam": "부산KCC",
      "state": "발매중",
      "closingDate": "2024-01-09 18:50:00"
    }, {
      "leagueName": "KOVO남",
      "dataId": "bd_G101_240005_0013",
      "data": [{
        "lose": "1.31",
        "draw": "0.0",
        "extraText": "null",
        "allotId": "bd_G101_240005_0013_0045",
        "win": "2.68",
        "gameNo": "45ⓢ"
      }, {
        "lose": "1.63",
        "draw": "1.5",
        "extraText": "H",
        "allotId": "bd_G101_240005_0013_0046",
        "win": "1.91",
        "gameNo": "46ⓢ"
      }, {
        "lose": "1.76",
        "draw": "181.5",
        "extraText": "U/O",
        "allotId": "bd_G101_240005_0013_0047",
        "win": "1.76",
        "gameNo": "47ⓢ"
      }],
      "awayTeam": "대한항공",
      "homeTeam": "KB손보",
      "state": "발매중",
      "closingDate": "2024-01-09 18:50:00"
    }, {
      "leagueName": "KOVO여",
      "dataId": "bd_G101_240005_0014",
      "data": [{
        "lose": "3.46",
        "draw": "0.0",
        "extraText": "null",
        "allotId": "bd_G101_240005_0014_0048",
        "win": "1.18",
        "gameNo": "48ⓢ"
      }, {
        "lose": "2.29",
        "draw": "-1.5",
        "extraText": "H",
        "allotId": "bd_G101_240005_0014_0049",
        "win": "1.43",
        "gameNo": "49ⓢ"
      }, {
        "lose": "1.76",
        "draw": "174.5",
        "extraText": "U/O",
        "allotId": "bd_G101_240005_0014_0050",
        "win": "1.76",
        "gameNo": "50ⓢ"
      }],
      "awayTeam": "도로공사",
      "homeTeam": "IBK기업",
      "state": "발매중",
      "closingDate": "2024-01-09 18:50:00"
    }, {
      "leagueName": "이FA컵",
      "dataId": "bd_G101_240005_0015",
      "data": [{
        "lose": "0.0",
        "draw": "0.0",
        "extraText": "null",
        "allotId": "bd_G101_240005_0015_0051",
        "win": "0.0",
        "gameNo": "51ⓢ"
      }, {
        "lose": "0.0",
        "draw": "0.0",
        "extraText": "H",
        "allotId": "bd_G101_240005_0015_0052",
        "win": "0.0",
        "gameNo": "52ⓢ"
      }, {
        "lose": "0.0",
        "draw": "0.0",
        "extraText": "U/O",
        "allotId": "bd_G101_240005_0015_0053",
        "win": "0.0",
        "gameNo": "53ⓢ"
      }],
      "awayTeam": "미정",
      "homeTeam": "미정",
      "state": "발매전",
      "closingDate": "-"
    }, {
      "leagueName": "이FA컵",
      "dataId": "bd_G101_240005_0016",
      "data": [{
        "lose": "3.15",
        "draw": "3.1",
        "extraText": "무",
        "allotId": "bd_G101_240005_0016_0054",
        "win": "1.96",
        "gameNo": "54ⓢ"
      }, {
        "lose": "1.63",
        "draw": "-1.0",
        "extraText": "H",
        "allotId": "bd_G101_240005_0016_0055",
        "win": "4.0",
        "gameNo": "55ⓢ"
      }, {
        "lose": "2.0",
        "draw": "2.5",
        "extraText": "U/O",
        "allotId": "bd_G101_240005_0016_0056",
        "win": "1.57",
        "gameNo": "56ⓢ"
      }],
      "awayTeam": "볼로냐",
      "homeTeam": "피오렌티",
      "state": "발매중",
      "closingDate": "2024-01-09 21:50:00"
    },{
      "leagueName": "NBA",
      "dataId": "bd_G101_240005_0019",
      "data": [{
        "lose": "0.0",
        "draw": "0.0",
        "extraText": "null",
        "allotId": "bd_G101_240005_0019_0064",
        "win": "0.0",
        "gameNo": "64"
      }, {
        "lose": "0.0",
        "draw": "0.0",
        "extraText": "H",
        "allotId": "bd_G101_240005_0019_0065",
        "win": "0.0",
        "gameNo": "65"
      }, {
        "lose": "0.0",
        "draw": "0.0",
        "extraText": "U/O",
        "allotId": "bd_G101_240005_0019_0066",
        "win": "0.0",
        "gameNo": "66"
      }],
      "awayTeam": "미네울브",
      "homeTeam": "올랜매직",
      "state": "발매전",
      "closingDate": "2024-01-10 08:50:00"
    }, {
      "leagueName": "NBA",
      "dataId": "bd_G101_240005_0020",
      "data": [{
        "lose": "0.0",
        "draw": "0.0",
        "extraText": "null",
        "allotId": "bd_G101_240005_0020_0067",
        "win": "0.0",
        "gameNo": "67"
      }, {
        "lose": "0.0",
        "draw": "0.0",
        "extraText": "H",
        "allotId": "bd_G101_240005_0020_0068",
        "win": "0.0",
        "gameNo": "68"
      }, {
        "lose": "0.0",
        "draw": "0.0",
        "extraText": "U/O",
        "allotId": "bd_G101_240005_0020_0069",
        "win": "0.0",
        "gameNo": "69"
      }],
      "awayTeam": "포틀트레",
      "homeTeam": "뉴욕닉스",
      "state": "발매전",
      "closingDate": "2024-01-10 09:20:00"
    }, {
      "leagueName": "NBA",
      "dataId": "bd_G101_240005_0021",
      "data": [{
        "lose": "0.0",
        "draw": "0.0",
        "extraText": "null",
        "allotId": "bd_G101_240005_0021_0070",
        "win": "0.0",
        "gameNo": "70ⓢ"
      }, {
        "lose": "0.0",
        "draw": "0.0",
        "extraText": "H",
        "allotId": "bd_G101_240005_0021_0071",
        "win": "0.0",
        "gameNo": "71ⓢ"
      }, {
        "lose": "0.0",
        "draw": "0.0",
        "extraText": "U/O",
        "allotId": "bd_G101_240005_0021_0072",
        "win": "0.0",
        "gameNo": "72ⓢ"
      }],
      "awayTeam": "멤피그리",
      "homeTeam": "댈러매버",
      "state": "발매전",
      "closingDate": "2024-01-10 10:20:00"
    }, {
      "leagueName": "NBA",
      "dataId": "bd_G101_240005_0022",
      "data": [{
        "lose": "0.0",
        "draw": "0.0",
        "extraText": "null",
        "allotId": "bd_G101_240005_0022_0073",
        "win": "0.0",
        "gameNo": "73ⓢ"
      }, {
        "lose": "0.0",
        "draw": "0.0",
        "extraText": "H",
        "allotId": "bd_G101_240005_0022_0074",
        "win": "0.0",
        "gameNo": "74ⓢ"
      }, {
        "lose": "0.0",
        "draw": "0.0",
        "extraText": "U/O",
        "allotId": "bd_G101_240005_0022_0075",
        "win": "0.0",
        "gameNo": "75ⓢ"
      }],
      "awayTeam": "토론랩터",
      "homeTeam": "LA레이커",
      "state": "발매전",
      "closingDate": "2024-01-10 12:20:00"
    }]
  }
  neverLandGameData.loadGameDataList("dummy_data_load");
}

class MainConfig {
  _powered_refresh_folder = false;
}

export const mainConfig = new MainConfig();

export const Environment = {
  isTest: true
};
export default loadDummyData;


export const round_management_table_body = [
  {year: '2024', round: '91', code:'G101_240091', closingDate: '2024-03-27 11:35', state: '진행중'},
  {year: '2024', round: '90', code:'G101_240090', closingDate: '2024-03-27 11:35', state: '마감'},
  {year: '2024', round: '89', code:'G101_240098', closingDate: '2024-03-27 11:35', state: '마감'},
  {year: '2024', round: '88', code:'G101_240088', closingDate: '2024-03-27 11:35', state: '마감'},
  {year: '2024', round: '87', code:'G101_240087', closingDate: '2024-03-27 11:35', state: '마감'},
  {year: '2024', round: '86', code:'G101_240086', closingDate: '2024-03-27 11:35', state: '마감'},
  {year: '2024', round: '85', code:'G101_240085', closingDate: '2024-03-27 11:35', state: '마감'},
  {year: '2024', round: '84', code:'G101_240084', closingDate: '2024-03-27 11:35', state: '마감'},
  {year: '2024', round: '83', code:'G101_240083', closingDate: '2024-03-27 11:35', state: '마감'},
  {year: '2024', round: '82', code:'G101_240082', closingDate: '2024-03-27 11:35', state: '마감'},
  {year: '2024', round: '81', code:'G101_240081', closingDate: '2024-03-27 11:35', state: '마감'},
  {year: '2024', round: '80', code:'G101_240080', closingDate: '2024-03-27 11:35', state: '마감'},
  {year: '2024', round: '79', code:'G101_240079', closingDate: '2024-03-27 11:35', state: '마감'},
  {year: '2024', round: '78', code:'G101_240078', closingDate: '2024-03-27 11:35', state: '마감'},
  {year: '2024', round: '77', code:'G101_240077', closingDate: '2024-03-27 11:35', state: '마감'},
  {year: '2024', round: '76', code:'G101_240076', closingDate: '2024-03-27 11:35', state: '마감'},
  {year: '2024', round: '75', code:'G101_240075', closingDate: '2024-03-27 11:35', state: '마감'},
  {year: '2024', round: '74', code:'G101_240074', closingDate: '2024-03-27 11:35', state: '마감'},
  {year: '2024', round: '73', code:'G101_240073', closingDate: '2024-03-27 11:35', state: '마감'},
  {year: '2024', round: '70', code:'G101_240072', closingDate: '2024-03-27 11:35', state: '마감'},
  {year: '2024', round: '71', code:'G101_240071', closingDate: '2024-03-27 11:35', state: '마감'},
  {year: '2024', round: '70', code:'G101_240070', closingDate: '2024-03-27 11:35', state: '마감'},
  {year: '2024', round: '69', code:'G101_240069', closingDate: '2024-03-27 11:35', state: '마감'},
  {year: '2024', round: '68', code:'G101_240068', closingDate: '2024-03-27 11:35', state: '마감'},
  {year: '2024', round: '67', code:'G101_240067', closingDate: '2024-03-27 11:35', state: '마감'},
  {year: '2024', round: '66', code:'G101_240066', closingDate: '2024-03-27 11:35', state: '마감'},
  {year: '2024', round: '65', code:'G101_240065', closingDate: '2024-03-27 11:35', state: '마감'},
  {year: '2024', round: '64', code:'G101_240064', closingDate: '2024-03-27 11:35', state: '마감'},
  {year: '2024', round: '63', code:'G101_240063', closingDate: '2024-03-27 11:35', state: '마감'},
  {year: '2024', round: '62', code:'G101_240062', closingDate: '2024-03-27 11:35', state: '마감'},
  {year: '2024', round: '61', code:'G101_240061', closingDate: '2024-03-27 11:35', state: '마감'},
  {year: '2024', round: '60', code:'G101_240060', closingDate: '2024-03-25 10:50', state: '마감'},
  {year: '2024', round: '59', code:'G101_240059', closingDate: '2024-03-22 11:20', state: '마감'},
  {year: '2024', round: '58', code:'G101_240058', closingDate: '2024-03-20 09:50', state: '마감'},
  {year: '2024', round: '57', code:'G101_240057', closingDate: '2024-03-18 10:20', state: '마감'},
  {year: '2024', round: '33', code:'G101_240033', closingDate: '2024-03-15 10:50', state: '마감'},
  {year: '2024', round: '32', code:'G101_240032', closingDate: '2024-03-13 10:50', state: '마감'},
  {year: '2024', round: '31', code:'G101_240031', closingDate: '2024-03-11 10:20', state: '마감'},
  {year: '2024', round: '30', code:'G101_240030', closingDate: '2024-03-08 11:50', state: '마감'},
  {year: '2024', round: '29', code:'G101_240029', closingDate: '2024-03-06 11:50', state: '마감'},
]

export const employee = [
  {name: '김땡땡', email: 'test@naver.com', phone: '010-0000-0000', registrationDate: '2024/01/01'},
  {name: '이땡땡', email: 'test123@naver.com', phone: '010-0000-0000', registrationDate: '2024/01/01'},
  {name: '박땡땡', email: 'test12@naver.com', phone: '010-0000-0000', registrationDate: '2024/01/01'},
  {name: '최땡땡', email: 'test1234@naver.com', phone: '010-0000-0000', registrationDate: '2024/01/01'},
  // More people...
]

export const game_management_table_body = [
  {
    event: "KBL", gameDate: '03.25(월) 19:00', category: '일반', state: '게임종료', team: '서울SK vs KT소닉붐',
    winRate: '1.91', drawRate:'0.00', criteria:'0', awayRate:'1.67', homeScore:'80', awayScore: '99',
    winAmount: '5345000',drawAmount:'', awayAmount:'5820500', gameResult:'away'
  },
  {
    event: "KBL", gameDate: '03.25(월) 19:00', category: '핸디캡', state: '게임종료', team: '서울SK vs KT소닉붐',
    winRate: '3.05', drawRate:'2.45', criteria:'0', awayRate:'2.50', homeScore:'80', awayScore: '99',
    winAmount: '1525000', drawAmount:'7489000', awayAmount:'7489000', gameResult:'win'
  },
  {
    event: "KBL", gameDate: '03.25(월) 19:00', category: '승오패', state: '게임종료', team: '서울SK vs KT소닉붐',
    winRate: '1.81', drawRate:'0.00', criteria:'1.5', awayRate:'1.75', homeScore:'81.5', awayScore: '99',
    winAmount: '970000', drawAmount:'', awayAmount:'13569000', gameResult:'draw'
  }
]
